import "./Styles/About.css"
import ReactGA from 'react-ga4';
import SmileBWLia from './Img/IMG_9293-2.jpg'
import Flexiblia from './Img/IMG_9397.jpg'
import Place from './Img/IMG_9150.jpg'
import {ReactComponent as Siluett} from '../../../siluett.svg'

ReactGA.send({ hitType: "pageview", page: "/about", title: "Rolam" });


export default function About () {



    return (

        <>
        <div className="aboutme-container">
            <div className="aboutme-inside-container"> 
                <div className="about-title">
                <h1 className="title-first">László</h1>
                <h1 className="title-second">Júlia</h1>
                </div>
                <img src={SmileBWLia} alt="smilia" />
                <p className="highlighted">Rólam</p>
                <p className="about-text"> A testmozgás mindig fontos szerepet töltött be az életemben. A mai napig szeretek új mozgásformákat kipróbálni, imádom a változatosságot.</p>
                <p className="about-text">A jógával bő 15 évvel ezelőtt találkoztam, amikor egyik barátnőm megkérdezte, hogy van-e kedvem kipróbálni. Nyitott vagyok az új ismeretek felé, szeretek új mozgásformákat kipróbálni, így el is mentem vele hatha jógára. Azonnal megtetszett sokszínűsége, pozitív hatásait már az első alkalommal megtapasztaltam: nyugalmat, kiegyensúlyozottságot éreztem, könnyebben ment a tanulás. Sőt, minden egyes alkalom után egyre erősebbé és hajlékonyabbá váltam.</p>
                

                <p className="about-text">Évekkel később épp a pályaválasztás előtt részleges bokaszalag szakadásom lett egy intenzív tánctáborban, amikor egy új elemet gyakoroltam. Ráadásként ugyanabban az évben síelés közben a rosszul beállított síléc miatt csavarodott a jobb térdem. Ezek miatt hosszú időre felmentést nyertem minden mozgás alól. Több szakembernél is megfordultam, kipróbáltam több gyógymódot is, de nem volt számottevő javulás. </p><p className="about-text">Elhatároztam, hogy ilyen fiatalon nem lehetek a mozgásomban korlátozott és teszek ellene: beleástam magam a témába és sikerült saját magam rehabilitálnom mindössze pár hónap alatt, főként a jóga terápiás gyakorlatait alkalmazva.</p>
                <img src={Flexiblia} alt="flexible lia" />

                <p className="about-text"> Ekkor döntöttem el, hogy szeretném ezt a tudást és a mozgás iránti szeretetem átadni másoknak is. Így még épp időben, az egyetemi felvételi eljárás elején változtattam a terveimen és építész helyett ‚Rekreációszervező és Egészségfejlesztő’ alapszakra jelentkeztem Győrbe. Főiskolai éveim alatt több ösztöndíjprogramban és tudományos projektben is részt vettem, ezáltal volt szerencsém Kínában, Dél-Afrikában és Thaiföldön önkéntesként és tudományos kutatóként részt venni a World Leisure Organisation világszervezet szárnyai alatt.</p>

                <p className="about-text">Az utolsó félévemet már a rekreációs szakmában elhelyezkedve Görögországban dolgozva töltöttem, így a már megtanultakat tudtam kamatoztatni a való világban is, amellett, hogy az angol nyelvtudásom is fejlődhetett ezáltal. Alapdiplomám megszerzése után volt szerencsém Indiában jógát és ájurvédát tanulni pár hónapig.</p>

                <p className="about-text">Onnan hazatérve 2019. április 13-án megnyitottam saját rekreáció- és jógastúdióm „Lia Fit” néven Mosonmagyaróváron, ahol csoportos jóga óráim mellett workshopokat, előadásokat, kirándulásokat és élménytáborokat szervezek felnőtteknek.</p>
                <img src={Place} alt="the place" />
                <p className="about-text">Tudásvágyam csak erősödött, így jelentkeztem a Testnevelési Egyetemre, ahol meg is szereztem ‚Humánkineziológusként’ a mesterdiplomám egyszerre két specializációval: ‚Terhelésélettan és Rehabilitáció’ valamint ‚Táplálkozástudomány és Sportdietetika’. 
                Ezáltal Lia Fit is fejlődött, mint ‚Életmód & Sportrehabilitációs Központ’ működött tovább, ahol személyre szabott életmód és sportrehabilitációs programokat dolgozok ki azoknak, akik szeretnék a sérülésük rehabilitálni, az életminőségük javítani. </p>

                <p className="about-text">
                Igyekszem a látóköröm szélesíteni és a tapasztalataim, tudásom átadni az erre nyitott embereknek. A mai napig rendszeresen járok továbbképzésekre mind itthon, mind külföldön egyaránt. 
                Célommá vált a mozgás örömének és gyógyító hatásainak megismertetése másokkal is. Holisztikus bölcselet követője vagyok. Szeretném, ha nem csak mozogni járnának hozzám, hanem hasonló érdeklődésű emberek toborzóhelye lenne, ahol egy közösség jön létre és az ember a barátaival töltheti az idejét. Erről szól a rekreáció is, ez az egészséges élet egyik alappillére.</p>
                
               {/*  <div className="about-first">
               
                <div className="text-container"><p className="about-text drop-in"> A testmozgás mindig fontos szerepet töltött be az életemben. A mai napig szeretek új mozgásformákat kipróbálni, imádom a változatosságot.</p>
                <p className="about-text drop-in-2">A jógával bő 15 évvel ezelőtt találkoztam, amikor egyik barátnőm megkérdezte, hogy van-e kedvem kipróbálni. Nyitott vagyok az új ismeretek felé, szeretek új mozgásformákat kipróbálni, így el is mentem vele hatha jógára. Azonnal megtetszett sokszínűsége, pozitív hatásait már az első alkalommal megtapasztaltam: nyugalmat, kiegyensúlyozottságot éreztem, könnyebben ment a tanulás. Sőt, minden egyes alkalom után egyre erősebbé és hajlékonyabbá váltam.</p></div>
                <div className="text-container">
                    <img className="about-img-transparent" src={TransparentPortrait} alt="liaportrait"/></div>
                </div>


                <div className="about-fourth" >
                <div className="text-container slide"><p className="about-text">Évekkel később épp a pályaválasztás előtt részleges bokaszalag szakadásom lett egy intenzív tánctáborban, amikor egy új elemet gyakoroltam. Ráadásként ugyanabban az évben síelés közben a rosszul beállított síléc miatt csavarodott a jobb térdem. Ezek miatt hosszú időre felmentést nyertem minden mozgás alól. Több szakembernél is megfordultam, kipróbáltam több gyógymódot is, de nem volt számottevő javulás. </p><p className="about-text">Elhatároztam, hogy ilyen fiatalon nem lehetek a mozgásomban korlátozott és teszek ellene: beleástam magam a témába és sikerült saját magam rehabilitálnom mindössze pár hónap alatt, főként a jóga terápiás gyakorlatait alkalmazva.</p></div>
                <div className="text-container slide"><img className="about-img-transparent" src={Tartasjavitas} alt="tartasjavitas"/>
                </div>
                </div>
   
                <div className="about-fifth" >
                <div className="text-container slide"><p className="about-text"> Ekkor döntöttem el, hogy szeretném ezt a tudást és a mozgás iránti szeretetem átadni másoknak is. Így még épp időben, az egyetemi felvételi eljárás elején változtattam a terveimen és építész helyett ‚Rekreációszervező és Egészségfejlesztő’ alapszakra jelentkeztem Győrbe. Főiskolai éveim alatt több ösztöndíjprogramban és tudományos projektben is részt vettem, ezáltal volt szerencsém Kínában, Dél-Afrikában és Thaiföldön önkéntesként és tudományos kutatóként részt venni a World Leisure Organisation világszervezet szárnyai alatt.</p></div>
                <div className="text-container slide"><img className="about-img" src={Ocean} alt="abroad" />
                </div>
                </div>
                
                <div className="about-sixth" >
                <div className="text-container slide"><p className="about-text">Az utolsó félévemet már a rekreációs szakmában elhelyezkedve Görögországban dolgozva töltöttem, így a már megtanultakat tudtam kamatoztatni a való világban is, amellett, hogy az angol nyelvtudásom is fejlődhetett ezáltal. Alapdiplomám megszerzése után volt szerencsém Indiában jógát és ájurvédát tanulni pár hónapig.</p></div>
                <div className="text-container slide "><img className="about-img" src={greekpic} alt="liaportrait"/></div>
                </div>
 
                <div className="about-seventh">
                <div className="text-container slide">
                    <p className="about-text">Onnan hazatérve 2019. április 13-án megnyitottam saját rekreáció- és jógastúdióm „Lia Fit” néven Mosonmagyaróváron, ahol csoportos jóga óráim mellett workshopokat, előadásokat, kirándulásokat és élménytáborokat szervezek felnőtteknek.</p></div>
                    <div className="text-container slide"><img className="about-img" src={Multisup} alt="liaportrait"/></div>
                    </div>
          
                <div className="about-eigth">
                <div className="text-container slide"><p className="about-text">Tudásvágyam csak erősödött, így jelentkeztem a Testnevelési Egyetemre, ahol meg is szereztem ‚Humánkineziológusként’ a mesterdiplomám egyszerre két specializációval: ‚Terhelésélettan és Rehabilitáció’ valamint ‚Táplálkozástudomány és Sportdietetika’. 
                Ezáltal Lia Fit is fejlődött, mint ‚Életmód & Sportrehabilitációs Központ’ működött tovább, ahol személyre szabott életmód és sportrehabilitációs programokat dolgozok ki azoknak, akik szeretnék a sérülésük rehabilitálni, az életminőségük javítani. </p></div>
                <div className="text-container slide"><img className="about-img" src={randompic} alt="liaportrait"/></div>
                </div>

                <div className="about-ninth">
                <div className="text-container slide"><p className="about-text">
                Igyekszem a látóköröm szélesíteni és a tapasztalataim, tudásom átadni az erre nyitott embereknek. A mai napig rendszeresen járok továbbképzésekre mind itthon, mind külföldön egyaránt. 
                Célommá vált a mozgás örömének és gyógyító hatásainak megismertetése másokkal is. Holisztikus bölcselet követője vagyok. Szeretném, ha nem csak mozogni járnának hozzám, hanem hasonló érdeklődésű emberek toborzóhelye lenne, ahol egy közösség jön létre és az ember a barátaival töltheti az idejét. Erről szól a rekreáció is, ez az egészséges élet egyik alappillére.</p></div>
                <div className="text-container slide"><img className="about-img" src={Liainwoods} alt="liaportrait"/></div>
                </div>
 */}<Siluett className="separator-sliuett" />
</div>
       </div> 
       </>
    );
}