import TelephoneIcon from '@mui/icons-material/Phone'
import PlaceIcon from '@mui/icons-material/Place'
import EmailIcon from '@mui/icons-material/Email';
import * as React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { SocialIcon } from 'react-social-icons';
import './Footer.css';
import { NavLink } from "react-router-dom";
import Newsletter from "./Newsletter"; 
import LiaLogoSvg from '../../liafit_szlogen_nelkul.svg'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-main">
                <div className="column logo-column">
                    <img src={LiaLogoSvg} alt="Logo" className="logo"/>
                    

                    <p>Csapatunk elkötelezett egy olyan barátságos és befogadó környezet megteremtése mellett, ahol mindenki jól érezheti magát, és támogatást élvezhet a fejlődése során.</p>
                    <div className="sitemap">
                    <h4>Oldaltérkép</h4>
                    <NavLink to="/home">Kezdőlap</NavLink>
                    <NavLink to="/about">Rólam</NavLink>
                    <NavLink to="/blog">Blog</NavLink>
                    <NavLink to="/rental">Bérlés</NavLink>
                    <NavLink to="/events">Események</NavLink>
                    <NavLink to="/book">Foglalás</NavLink>
                    <NavLink to="/datapolicy">Adatvédelmi irányelvek</NavLink>
                    <NavLink to="/impresszum">Impresszum</NavLink></div>
                </div>
                <div className="column contact-column">
                    <h2 className="footer-h2">Kapcsolat</h2>
                    <ul>
                        <li><a className="socialfooter" href="https://goo.gl/maps/LkqhVxXhma38usMe6 " target="_blank" rel="noreferrer"><PlaceIcon></PlaceIcon>Mosonmagyaróvár, Szellőrózsa u. 33-37, 9200</a></li>
                        <li><a className="socialfooter" href="tel:+36703836041"><TelephoneIcon></TelephoneIcon>+36703836041</a></li>
                        <li><a className="socialfooter" href="mailto:info@liafit.hu"><EmailIcon></EmailIcon>info@liafit.hu</a></li>
                    </ul>
                    <h2 className="footer-h2">Kövess minket</h2>
                    <div className="social-icons">
                    <SocialIcon style={{transition:"0.5s"}} target="_blank" url="https://www.facebook.com/julialaszlo.liafit/" />
                    <SocialIcon style={{transition:"0.5s"}} target="_blank" url="https://www.instagram.com/liafit_movar/" />
                    <SocialIcon style={{transition:"0.5s"}} target="_blank" url="https://www.tiktok.com/@liafit9200?_t=8eHpUYzDTUw&_r=1" />
                    </div>
                </div>
                <div className="column follow-column">
                    <Newsletter />
                    
                </div>
            </div>
            <div className="footer-copyright">
                <p>Lia Fit © 2024 Minden jog fenntartva.</p>
                <a href="#top"><ArrowUpwardIcon /></a>
            </div>
        </footer>
    );
}

export default Footer;
