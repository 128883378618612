import React, {useState} from "react";
import sanityClient from "../../../client.js";
import {useParams} from "react-router-dom"; 
import BlockContent from "@sanity/block-content-to-react";
import Footer from "../Footer.js"; 
import CircularProgress from '@mui/material/CircularProgress'; 
import {useEffect} from 'react'

import ReactGA from 'react-ga4';






export default function SinglePost() {



  const [singlePost, setSinglePost] = useState(null); // Setting up a state variable for the single post
  const { slug } = useParams(); // Getting the slug parameter from the URL using the useParams hook

  ReactGA.send({
    hitType: "pageview",
    page: `/blog/${slug}`,
    title: slug,
  });

  useEffect(() => { // Running a side effect when the component mounts or the slug parameter changes
    sanityClient // Using the Sanity client to fetch the data for the single post
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            publishedAt,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body,
            "name": author->name,
            "authorImage": author->image{
              asset->{
                  _id,
                  url
              }
          },
            "bio": author->bio,
            "instagram": author->linkINSTA,
            "facebook": author->linkFB,
            "other": author->linkOTHER
        }`
      )
      .then((data) => setSinglePost(data[0])) // Updating the state variable with the fetched data
      .catch(console.error); // Logging any errors to the console
  }, [slug]); // Re-running the effect whenever the slug parameter changes

  // If the single post data is not yet available, display a loading spinner
  if (!singlePost) return (
    <div className="container" style={{height: "100vh"}}>
      <div className="loading" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width:"100vw"}}>
        <CircularProgress />
      </div>
    </div>
  );

  // If the single post data is available, display the post content and the footer component
  return (
    <>
      <div className="blog-main-container-single">
  <div className="single-post">
    {singlePost.mainImage && (
     
        <img
          className="blog-main-title-image"
          src={singlePost.mainImage.asset.url}
          alt={singlePost.title}
        />
    )}

<div className="home-main-hero-blog trans drop-in">
          <h1 className="drop-in">{singlePost.title}</h1>
          {singlePost.name && (
            <p className="signature">Szerző: {singlePost.name}</p>
          )}
          {singlePost.publishedAt && (
            <p>
              {new Date(singlePost.publishedAt).toLocaleDateString()}
            </p>
          )}
        </div>
    <div className="bloc-social-main-side">
      <div className="block-content">
        {singlePost.body && (
          <BlockContent
            blocks={singlePost.body}
            projectId="d45nmhil"
            dataset="production"
          />
        )}
      </div>

      <div className="social-media-block">
        <div className="singlepost-author-bio">
          {singlePost.name && singlePost.authorImage && (
            <div className="bio-header">
              <p>{singlePost.name}</p>
              <img
                className="singlepost-author-image"
                src={singlePost.authorImage.asset.url}
                alt={singlePost.name}
              />
            </div>
          )}
          {singlePost.bio && <p>{singlePost.bio}</p>}
        </div>

        <h2>#Kövess</h2>
        {singlePost.facebook && (
          <p>
            <a
              className="socialfooter"
              href={singlePost.facebook}
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </p>
        )}
        {singlePost.instagram && (
          <p>
            <a
              className="socialfooter"
              href={singlePost.instagram}
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </p>
        )}
        {singlePost.other && (
          <p>
            <a
              className="socialfooter"
              href={singlePost.other}
              target="_blank"
              rel="noreferrer"
            >
              Egyéb Link
            </a>
          </p>
        )}
      </div>
    </div>
  </div>
</div>


  {/* Displaying the footer component */}
  <Footer />
</>
);
}

//In summary, this code defines a React component called `SinglePost` that fetches and displays the content of a single blog post from a Sanity CMS instance. The component uses the `useParams` hook from React Router to get the slug parameter from the URL, which is then used to fetch the relevant post data from the Sanity client. If the data is not yet available, a loading spinner is displayed. If the data is available, the post content is displayed along with social media links and a footer component. The post content is rendered using the `BlockContent` component from Sanity.
