import "./book.css"
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Footer from "../Footer.js";
import Instructors from "../Instructors.js"
import Classes from '../Classes.js'
import {useRef} from 'react'
import ReactGA from 'react-ga4';


ReactGA.send({ hitType: "pageview", page: "/book", title: "Foglalas" });


export default function Book () {


  const ref1 = useRef(null);

  const handleClick1 = () => {
    ref1.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const ref2 = useRef(null);

  const handleClick2 = () => {
    ref2.current?.scrollIntoView({ behavior: 'smooth' });
  };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

return (
<div>
<div className="book-main-container">

  <div className="side-title-back">
  <div className="title-text-container">
        <h1 className="side-title-text drop-in">Foglalás</h1>
    </div>
  </div>

<div className="book-modal">
      <button className="openbutton" onClick={handleOpen}>Házirend</button>
      <button  className="openbutton" onClick={handleClick1}>Oktatóink</button>
      <button  className="openbutton" onClick={handleClick2}>Óráink</button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        
      >
        <Fade in={open}>
          <Box className="modal-box-style">
            <Typography id="transition-modal-title" variant="h6" component="h2">
            <h2>Házirend</h2>
            </Typography>
            <button className="modal-close" onClick={handleClose}><CloseIcon /></button>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <div className="rules">
            <h3>Kérlek olvassátok el figyelmesen</h3>
            <h3>A közös érdekeink szem előtt tartva vezetem be az alábbi szabályokat:</h3>
              <ul>
                <li>Az órák <strong>limitáltak</strong> és előzetes <strong>bejelentkezés</strong> szükséges.</li>
                <li>Az órákón maximum 20 fő vehet részt</li>
                <li>Ha időpont nélkül érkezel, az edzést a szabad helyek függvényében kezdheted meg.</li>
                <li>A foglalási rendszer 0-24 órában működik.</li>
                <li>Az óra kezdete előtt legalább 2 órával lehet módosítani vagy törölni a foglalást.</li>
                <li>Bejelentkezni az óra kezdete előtt közvetlenül is lehetséges a bejelentkezési felületen.</li>
                <li>Érkezz időben: óra kezdete előtt 15-5 perccel.</li>
                <li>A jógaterem igényesen felszerelt: jógaszőnyeg, hengerpárna pléddel, de ha van sajátod, azt is nyugodtan hozd el és használd</li>
                <li>Az órák min. 5 fő esetén kerülnek megtartásra. Ha az adott órára nincs meg a minimum 5 fő délelötti órák esetén előző este 18:00-ig, délutáni órák esetében aktuális nap 12:00-ig, az óra sajnos elmarad, melyről a foglalási rendszer automatikusan értesíti az elmaradó órára bejelentkezetteket E-mailben.</li>
                <li>Kérlek a terembe csak a jógázáshoz szükséges eszközöket hozd be (jógaszőnyeg, zokni, pulcsi, pléd...). Minden mást hagyj a termen kívül!</li>
                <li>A magatokkal hozott értéktárgyakért felelősséget nem vállalunk!</li>
                <li>Használat után minden eszközt fertőtleníteni kell az erre a célra kihelyezett fertőtlenítővel!</li>
                <li>Ha betegnek érzed magad, ki kell hagynod az órát, maradj otthon, pihend ki magad és gyógyulj meg mihamarabb.</li>
              </ul>
              <h3>
              Köszönjük az együttműködést és a megértést
              </h3>
          </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
<div className="inside-container">
    <div id="booked4us">
            <iframe title="idopontfoglalas" src='https://liafit.booked4.us/public/?CalendarId=1&Mode=iframe&IframeWidth=100%25&IframeHeight=600px&SchemaName=liafit' style={{width:"100%", height:"800px", border: "none", margin:"auto"}}></iframe>
            </div>
<div className="booking-titles">
<h2 ref={ref1}>
Oktatóink
</h2>
</div>
<Instructors  />

<div className="booking-titles">
<h2 ref={ref2}>
Óráink
</h2>
</div>
<Classes  />


    {/*         <div className="cards-container" style={{maxWidth:"1000px"}}>
            <div className="cards">
              <img src={instructor1} alt="Instructor 5" />
              <h3>Gentle Yoga</h3>
              <p>Tuesdays and Thursdays 9:00am-10:00am</p>
              <p className="price">$15 per class</p>
            </div>

            <div className="cards">
              <img src={instructor1} alt="Instructor 5" />
              <h3>Yoga for Stress Relief</h3>
              <p>Tuesdays and Thursdays 9:00am-10:00am</p>
              <p className="price">$15 per class</p>
            </div>

            <div className="cards">
              <img src={instructor1} alt="Instructor 5" />
              <h3>Restorative Yoga</h3>
              <p>Saturdays 10:00am-11:30am</p>
              <p className="price">$25 per class</p>
            </div>

            <div className="cards">
              <img src={instructor1} alt="Instructor 5" />
              <h3>Vinyasa Flow</h3>
              <p>Mondays and Wednesdays 6:00pm-7:00pm</p>
              <p className="price">$20 per class</p>
            </div>   
            
</div> */}
</div>
</div>
<Footer />
</div>

);



}