import React from "react";
import Footer from "../Footer.js";
import './uniqueclasses.css'
import Pic1 from './Media/IMG_0784.jpg'
import Pic2 from './Media/IMG_0780.jpg'
import { NavLink } from "react-router-dom";
import ReactGA from 'react-ga4';




ReactGA.send({ hitType: "pageview", page: "/humankineziologia", title: "Humankineziologia & Joga Liaval" });

function Humankineziologia() {
  


  return (
    <div>
            
    <div className="book-main-container"> 

<div className="unique-class-title-lia">
<div className="unique-class-title-left">

<h1>Humánkineziológia & Jóga Liával</h1>
<p className="title-short-desc">Egyéni sportrehabilitáció, teljesítményfokozás, jógaterápia, jóga, táplálkozási- és életmód tanácsadás</p>

<div className="personal-data-lia">
        <a href = "mailto:info@liafit.hu">info@liafit.hu</a>
        <a href="tel:+36703836041">+36 70 383 6041</a>
    </div>
</div>
</div>

<p className="title-short-desc-under">Célom a mozgás örömének és gyógyító hatásainak megismertetése másokkal is. Segíteni az embereket önmaguk jobb megismerésében és az önfejlesztésben testgyakorlatokkal, légzőgyakorlatokkal, relaxációs és meditációs technikák által, miközben a holisztikus és prevenciós szemléleteket helyezem a középpontba.</p>

<div className="container">
  <div className="inside-container">


<div className="class-description">




<div className="class-description-text">
<h2 className="class-description-h2">Sportszakemberként fő szolgáltatásaim:</h2>

<p className="list-main">Komplex állapotfelmérés
</p>
<ul>
    <li>Megbeszéljük a céljaid, feltérképezem az esetleges problémákat és ezek alapján összeállítom a számodra legmegfelelőbb gyakorlatokat. Szükség esetén további orvosi vizsgálatokat javaslok</li>
</ul>

<p className="list-main">Személyre szabott sportrehabilitáció és/vagy jógaterápia tervezése és lebonyolítása
</p>
<ul>
    <li>Sérülésből, műtétből, tartáshibákból vagy életmódból adódóan</li>
</ul>

<p className="list-main">Egyéni jógaórák
</p>
<ul>
    <li>A saját ütemedben sajátíthatod el a jógát az alapoktól egészen a profi szintig</li>
    <li>Asana – testgyakorlatok</li>
    <li>Pranayama - légzőgyakorlatok</li>
    <li>Stresszkezelő, relaxációs és meditációs technikák</li>
</ul>

<p className="list-main">Személyre szabott útmutatás a kiegyensúlyozott napi rutin és étkezési szokások kialakításához
</p>

<p className="list-main">Amikben még tudok segíteni:
</p>
<ul>
    <li>Hormonális háztartás harmonizálása</li>
    <li>Idegrendszer- és immunrendszer erősítése</li>
    <li>Általános közérzet javítása</li>
    <li>Általános erőnlét, állóképesség, hajlékonyság, mobilitás, rugalmasság növelése</li>
</ul>


</div>

<div className="class-description-pictures">
 <img src={Pic1}/>
<img src={Pic2}/>


</div>

</div>

<div className="introduction">

</div>

</div>      
</div>


<p className="keress-batran">Keress bátran kortól, nemtől, előképzettségtől függetlenül.</p>

<div className="random-div">
<NavLink className="rolam-unique" to="/about">László Júlia</NavLink>
</div>
</div>
<Footer />
</div>
  );
}

export default Humankineziologia;