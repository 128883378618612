import './App.css';
import Navbar from "./Navbar.js"
import {useEffect} from "react"
import { Fragment, React } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Components/Pages/About/About.js"
import Home from './Components/Pages/Home/Home.js';
import Book from './Components/Pages/Book/Book.js';
import Events from "./Components/Pages/Events/Events.js"
import Rental from "./Components/Pages/Rental/Rental.js"
import Blog from "./Components/Pages/Blog/Blog.js"
import SinglePost from "./Components/Pages/Blog/SinglePost.js"
import ContactUs from "./Components/Pages/Contact/ContactUs.js"
import DataPolicy from "./Components/Pages/DataPolicy.js"
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom";
import Popup from './Components/Pages/Popup.js'
import Popup2 from './Components/Pages/Popup2.js'
import Impresszum from './Components/Pages/Impresszum.js'
import Lifestyle from './Components/Pages/Lifestyle/Lifestyle.js';
import Humankineziologia from './Components/Pages/Uniqueclasses/Humankineziologia.js';
import PersonalTraining from './Components/Pages/Uniqueclasses/PersonalTraining.js';

ReactGA.initialize([
  {
    trackingId: "G-4E7YHG74N4",
  }
]);

function App() {

  // Function to handle animations on scroll
  function handleScroll() {
    const slideEls = document.querySelectorAll(".slide");
    
    slideEls.forEach((el) => {
      const slideInAt = window.scrollY + window.innerHeight * 0.9;
      const elBottom = el.offsetTop + el.clientHeight;
      const isHalfShown = slideInAt > el.offsetTop;
      const isNotScrolledPast = window.scrollY < elBottom;
      if (isHalfShown && isNotScrolledPast) {
        el.classList.add("animate");
      } else {
        el.classList.remove("animate");
      }
    });
  }

  // Adding an event listener for the scroll event
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  // Rendering the main structure of the application using React Router
  return (
    <>
    <Fragment>
        <ToastContainer />
			  <Router>
			  <Navbar/>
			  <Routes>
				<Route path="/" element={<Home />} />
				<Route path="/blog" element={<Blog />}/>
				<Route path="/blog/:slug" element={<SinglePost />}/>
				<Route path="/events" element={<Events />} />
        <Route path="/events/:eventId" element={<Events />} />
				<Route path="/rental" element={<Rental />}/>
				<Route path="/book" element={<Book />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/contactus" element={<ContactUs />}/>

        <Route path="/lifestyle" element={<Lifestyle />}/>

        <Route path="/uniqueclasses/personaltraining" element={<PersonalTraining />}/>
        <Route path="/uniqueclasses/humankinezilogia" element={<Humankineziologia />}/>

        <Route path="/datapolicy" element={<DataPolicy />}/>
        <Route path="/impresszum" element={<Impresszum />}/>
    		</Routes>
        <Popup2 /> 

        <CookieConsent buttonText={"Ok"}>Ez a weboldal Sütiket használ. A weboldal böngészésével elfogadod a sütik használatát és az Adatvédelmi irányelveinket. <NavLink    style={{textDecoration:"underline"}} to="/datapolicy">(Adatvédelmi irányelvek.)</NavLink></CookieConsent>
			  </Router> 
		</Fragment>
    </>
  );
}
export default App;
