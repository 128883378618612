import React, { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkIcon from "@mui/icons-material/Link";
import { Modal, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ReactMarkdown from "react-markdown";

export default function Instructors() {
  const [instructorsData, setInstructorsData] = useState(null);
  const [selectedInstructor, setSelectedInstructor] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "instructors"] | order(orderNumber asc) {
          _id,
          name,
          description,
          formattedDescription,
          tags,
          linkFB,
          linkINSTA,
          linkOTHER,
          mainImage {
            asset->{_id, url}
          },
        }`
      )
      .then((data) => setInstructorsData(data))
      .catch(console.error);
  }, []);

  const handleOpenModal = (instructor) => {
    setSelectedInstructor(instructor);
  };

  const handleCloseModal = () => {
    setSelectedInstructor(null);
  };

  const renderMarkdownBlocks = (blocks) => {
    return blocks.map((block, index) => {
      if (block._type === "block") {
        return (
          <ReactMarkdown key={index}>
            {block.children.map((child) => child.text).join("")}
          </ReactMarkdown>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="cards-container">
      {instructorsData &&
        instructorsData.map((instructor, index) => (
          <div key={index}>
            <div className="cards" key={index}>
              {instructor.mainImage && (
                <img
                  src={instructor.mainImage.asset.url}
                  alt={instructor.name}
                />
              )}
              <div className="cards-textbox instructors">
                {instructor.name && (
                  <>
                    <button onClick={() => handleOpenModal(instructor)}>
                      <h3> {instructor.name} </h3>
                    </button>
                    <>
                      <button
                        onClick={() => handleOpenModal(instructor)}
                        className="read-more-blog"
                      >
                        Olvass Tovább
                      </button>
                    </>
                    <ul>
                      {instructor.tags &&
                        instructor.tags.map((tag, index) => (
                          <li key={index}>{tag}</li>
                        ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="card-social-icons">
              {instructor.linkFB && (
                <a
                  href={instructor.linkFB}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FacebookIcon
                    className="card-social-icon-mui"
                    style={{ fontSize: "28px" }}
                  />
                </a>
              )}
              {instructor.linkINSTA && (
                <a
                  href={instructor.linkINSTA}
                  rel="noreferrer"
                  target="_blank"
                >
                  <InstagramIcon
                    className="card-social-icon-mui"
                    style={{ fontSize: "28px" }}
                  />
                </a>
              )}
              {instructor.linkOTHER && (
                <a
                  href={instructor.linkOTHER}
                  rel="noreferrer"
                  target="_blank"
                >
                  <LinkIcon
                    className="card-social-icon-mui"
                    style={{ fontSize: "28px" }}
                  />
                </a>
              )}
            </div>
          </div>
        ))}

      {/* Modal */}
      <Modal
        open={selectedInstructor !== null}
        onClose={handleCloseModal}
        aria-labelledby="instructor-modal"
        aria-describedby="instructor-details"
      >
        <Fade in={selectedInstructor !== null}>
          <Box className="instructor-modal">
            <button
              className="modal-close-button-cards"
              onClick={handleCloseModal}
            >
              <ClearOutlinedIcon />
            </button>
            {selectedInstructor && (
              <>
                <div className="instructor-sub-side-modal">
                  {selectedInstructor.mainImage && (
                    <img
                      src={selectedInstructor.mainImage.asset.url}
                      alt={selectedInstructor.name}
                    />
                  )}
                  <h2>{selectedInstructor.name}</h2>
                  <div className="card-social-icons-modal">
                    {selectedInstructor.linkFB && (
                      <a
                        href={selectedInstructor.linkFB}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FacebookIcon
                          className="card-social-icon-mui"
                          style={{ fontSize: "28px" }}
                        />
                      </a>
                    )}
                    {selectedInstructor.linkINSTA && (
                      <a
                        href={selectedInstructor.linkINSTA}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <InstagramIcon
                          className="card-social-icon-mui"
                          style={{ fontSize: "28px" }}
                        />
                      </a>
                    )}
                    {selectedInstructor.linkOTHER && (
                      <a
                        href={selectedInstructor.linkOTHER}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <LinkIcon
                          className="card-social-icon-mui"
                          style={{ fontSize: "28px" }}
                        />
                      </a>
                    )}
                  </div>
                  {selectedInstructor.tags && (
                    <ul>
                      {selectedInstructor.tags.map((tag, index) => (
                        <li key={index}>{tag}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="instructor-main-side-modal">
                  {selectedInstructor.formattedDescription && renderMarkdownBlocks(selectedInstructor.formattedDescription)}
                </div>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
