import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import './lifestyle.css'
import kettentevekeny from './Media/kettentevekeny.jpg'
import martin_logo from './Media/martin_logo.png'
import lialogo from '../../../liafit_szlogen_nelkul.svg';
import Footer from '../Footer'
import FloatingButton from './FloatingButton';
import ImageGallery from "react-image-gallery";
import InstructorSelected from '../InstructorSelected';


import Pic1 from './Media/IMG_0678-2.jpg'
import Pic2 from './Media/IMG_0706(1).jpg'
import Pic3 from './Media/IMG_0787.jpg'
import Pic4 from './Media/IMG_0790.jpg'

import Pic5 from './Media/IMG_0829.jpg'
import Pic6 from './Media/IMG_9371.jpg'
import Pic7 from './Media/IMG_9445-Edit.jpg'
import Pic8 from './Media/IMG_9454-2.jpg'
import pic9 from './Media/IMG_1290.jpg'
import Pic10 from './Media/IMG_0874-2(1).jpg'
import Pic11 from './Media/IMG_0806.jpeg'

const images = [
    {
      original: Pic1,
    },
    {
      original: Pic2,
    },
    {
      original: Pic3,
    },
    {
      original: Pic4,
    },
    {
      original: Pic5,
    },
    {
      original: Pic6,
    },
    {
      original: Pic7,
    },
    {
      original: Pic8,
    },
    {
      original: Pic10,
    },
    {
      original: Pic11,
    },
  ];


ReactGA.send({ hitType: "pageview", page: "/lifestyle", title: "Lifestyle" });


export default function Lifestyle () {

    const [isDetailedPlanOpen, setIsDetailedPlanOpen] = useState(false);

    const toggleDetailedPlan = () => {
      setIsDetailedPlanOpen(!isDetailedPlanOpen);
    };

    return (<>
<div className="lifestyle-container">

<section className="lifestlye-title-section">
<div className="title-text">
    <section><h1>
      <span className="cim-egy">Urald </span>
      <span className="cim-ketto">a tested és lelked!</span>
      
</h1>
<h2>
Teszt alapú<br></br> komplex életmódváltó program
</h2>
<div className="title-logos">
   <img className="title-logos-each" src={martin_logo} alt="Martin logoja" />
   &
   <img className="title-logos-each" src={lialogo} alt="Lia logoja" />
</div>
</section>
</div>

<img className="title-pic" src={kettentevekeny} alt="kettenworkout"/>

</section>

{/* <div className="title-logos">
   <img className="title-logos-each" src={martin_logo} alt="Martin logoja" />
   <HandshakeIcon className="title-logos-each" />
   <img className="title-logos-each" src={lialogo} alt="Lia logoja" />
</div> */}

<section className="lifestyle-services-section">

<h2 className="services-title">
Szolgáltatások
</h2>

<div className="services-boxes"><div className="services-box"><h4>Teszt alapú program</h4></div>
<div className="services-box"><h4>Funkcionális edzés</h4><ul>
    <li>Erőfejlesztés</li>
    <li>Állóképességfejlesztés</li>
    <li>Alakformálás</li>
</ul></div>
<div className="services-box"><h4>Jóga</h4>
<ul>
    <li>Hajlékonyság és mobilitás fejlesztése</li>
    <li>Légzőgyakorlatok</li>
    <li>Relaxációs és meditációs technikák</li>
</ul></div>
<div className="services-box"><h4>Órákat rövid elmélettel zárjuk</h4>

    <p className="felsorolas-szeru">Hogy be tudd illeszteni az elhangzottakat a hétköznapi rutinodba</p>

</div>



<div className="services-box"><h4>Egészséges receptek</h4></div></div>

</section>


<div className="services-timetable-group">


<section className="lifestyle-detailed-plan-seection">

<div className="mobile-wide-sectioner-2">
<section className="lifestyle-timetable-section">
<h3>Kezdés: 2024. ÁPRILIS 26</h3>
<h4>Időpontok (8 héten keresztül):</h4>
<ul><li>Kedd	17:30 – 19:00</li>
<li>Péntek	17:30 – 19:30</li>
    </ul>
    <p>Az óra első felében funkcionális edzés Martinnal,<br></br> az óra második felében pedig Lia vezet be a jóga alapjaiba.</p>
</section>

<section>
    <div className={`detailed-plan-table ${isDetailedPlanOpen ? 'open' : 'closed'}`}>

            <div className="plan-row plabel" ><div className="plan-first ">Időpont</div><div className="plan-second"></div>
            <div className="plan-third ">Edzés végi témák</div></div>

            <div className="plan-row"><span>1.</span><div className="plan-first">április 26. (P)</div><div className="plan-second">Kezdeti tesztek</div>
            <div className="plan-third"></div></div>

            <div className="plan-row"><span>2.</span><div className="plan-first"> április 30. (K)</div><div className="plan-second">Első foglalkozás</div>
            <div className="plan-third">Mentális gyakorlat -terhek elengedése</div></div>

            <div className="plan-row"><span>3.</span><div className="plan-first"> május 3. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Nem testedzés jellegű fizikai aktivitás</div></div>

            <div className="plan-row"><span>4.</span><div className="plan-first"> május 7. (K)</div><div className="plan-second"></div>
            <div className="plan-third">Izomláz</div></div>

            <div className="plan-row"><span>5.</span><div className="plan-first"> május 10. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Görcs</div></div>

            <div className="plan-row"><span>6.</span><div className="plan-first"> május 14. (K)</div><div className="plan-second"></div>
            <div className="plan-third">Feldolgozott élelmiszerek</div></div>

            <div className="plan-row"><span>7.</span><div className="plan-first"> május 17. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Glutén, tejtermékek, szója</div></div>

            <div className="plan-row"><span>8.</span><div className="plan-first"> május 21. (K)</div><div className="plan-second"></div>
            <div className="plan-third">Alvás</div></div>

            <div className="plan-row"><span>9.</span><div className="plan-first"> május 24. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Balance teszt kiértékelése</div></div>

            <div className="plan-row"><span>10.</span><div className="plan-first"> május 28. (K)</div><div className="plan-second"></div>
            <div className="plan-third">Omega-3</div></div>

            <div className="plan-row"><span>11.</span><div className="plan-first"> május 31. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Prebiotikus rostok</div></div>

            <div className="plan-row"><span>12.</span><div className="plan-first"> június 4. (K)</div><div className="plan-second"></div>
            <div className="plan-third">D-Vitamin</div></div>

            <div className="plan-row"><span>13.</span><div className="plan-first"> június 7. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Táplálékkiegészítők</div></div>

            <div className="plan-row"><span>14.</span><div className="plan-first"> június 11. (K)</div><div className="plan-second"></div>
            <div className="plan-third">Függőség</div></div>

            <div className="plan-row"><span>15.</span><div className="plan-first"> június 14. (P)</div><div className="plan-second"></div>
            <div className="plan-third">Fermentácó</div></div>

            <div className="plan-row"><span>16.</span><div className="plan-first"> június 18. (K)</div><div className="plan-second">Utolsó foglalkozás</div>
            <div className="plan-third">Alternatív édességek</div></div>

            <div className="plan-row"><span>17.</span><div className="plan-first"> június 21. (P)</div><div className="plan-second">Kontrol tesztelések</div>
            <div className="plan-third"></div></div>


        </div>
        <button className="show-more-button" onClick={toggleDetailedPlan}>
            {isDetailedPlanOpen ? 'Bezárás' : 'Részletes terv megjelenítése'}
          </button>
        <p className="change-right">A változtatás jogát fenntartjuk.</p>
</section>

    </div>  


</section>
</div>

{/* <div className="lifestyle-participant-section">
        <div>
        Stanka Martin
        SMartin Health logo
        </div>

        <div>
        László Júlia
        Lia Fit logo
        </div>
    </div> */}


{/* <section className="lifestyle-picture-slide">
    <img src={Pic1} alt="egykep" />
    <img src={Pic8} alt="" />
    <img src={Pic3} alt="haromkep" />
    <img src={Pic7} alt="" />
    <img src={Pic4} alt="negykep" />
    <img src={Pic5} alt="otkep" />

    <img src={Pic6} alt="" />

    <img src={Pic2} alt="ketkep" />
    
</section> */}

<div className="mobile-wide-sectioner">

<section className="lifestyle-explanation-section">
    <h2>Tesztek</h2>
    
    <ul><li>Anamnézis: betegségek, tünetek</li>
    <li>Szubjektív teszt</li>
    <li>Testösszetétel, tápláltsági profil és antropometriai mérések – testtömeg-index (BMI), testzsírszázalék, kerület mérések</li>
            <li>Fogáserő mérése – az általános erőnlétre következtetés</li>
            <li>Hajlékonysági teszt – térdhajlítóizmok nyújthatósága, csípőízületi mozgásterjedelem</li>
            <li>Előtte-utána fényképek</li>
    <li>BalanceTest – száraz vércseppes teszt, amellyel elemezhetjük a kapilláris vérben lévő 11 zsírsav mennyiségét, mely tükrözi az elmúlt 120 nap étrendjét. Ez a profil szoros összefüggésben van az egészségi állapottal, általa egyértelműen tudunk következtetni a hibás és helyes étkezési szokásokra.</li>
    
        </ul>

   

</section>

<section className="lifestyle-explanation-picture-section">

<img src={pic9} alt="tesztek" className="tests-picture"/>

</section>

</div>
<div className="motivation-text">
  <p>Tedd meg az első lépést egészségesebb, jobb önmagad felé!</p>
<p>
     Célunk, hogy urald a tested és a lelked és segítsünk olyan egészséges szokásokat kialakítani,<br></br> hogy fitt legyél a következő 30-60 évben és jól érezd magad a bőrödben.
    </p>
    </div>
<div className="price-price"><p>Teljes ár</p><span>119 000 Ft</span></div>
<section className="lifestyle-apply-section">

<div className="lifestyle-price">
    
    <div className="early-bird" ><p>EARLY BIRD kedvezménnyel az első 5 jelentkezőnek</p> <span>99 000 Ft</span></div>
    <div className="part-payment"><p>Részletfizetési lehetőség:</p></div>
    <div><p>Jelentkezéskor </p> <span>60.000 Ft</span>
         </div> 
    <div><p>Május 15-ig</p> <span>Fennmaradó összeg</span></div>
        
</div>

<div className="apply-process" id="apply-section">
    <h3>Fontos tudnivalók</h3>
    <ul>
        <li>
        Amikor elküldöd a jelentkezésed, először csak egy levelet kapsz a számlázásról és a további pontos részletekről
        </li>
        <li>
        Ez után el kell utalnod a tanfolyam előlegét ami <strong>60.000 Ft</strong>
        </li>

        <li>
        A jelentkezés elküldése nem elégséhez ahhoz, hogy bekerülj a programba!
        </li>
        <li>
        A jelentkezés az <strong>előleg befizetése után</strong> válik véglegessé!
        </li>
        <li>
        A helyek a befizetések megérkezésének sorrendjében töltődnek
        </li>
        <li>
        Lehet, hogy a jelentkezésed időben elküldted, de késlekedtél az utalással, vagy más sokkal gyorsabb volt és közben a program betelt. Ha ilyen történne visszautaljuk a díjat. 
        </li>
    </ul>
</div>

</section>

<section className="google-form-section">

   <button className="google-form-link">
    <a href="https://forms.gle/oZVffFqZkBuFzCJu8" rel="noreferrer"  target="_blank">
   Ide kattintva tudsz jelentkezni.
    </a>
   </button>

  {/*   <iframe className="google-form-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLScqem_xGMTbJ0BnnVy1HedY4vsokXoKfAMnQ17scL6dVwhUrg/viewform?embedded=true"   frameborder="0" marginheight="0" marginwidth="0">Betöltés…</iframe>
 */}
 
</section>

<InstructorSelected  filteredNames={["László Júlia", "Stanka Martin"]} />
<section className="image-gallery-section">
<ImageGallery items={images} infinite={true} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} showNav={false} autoPlay={true} slideDuration={1500} slideInterval={10000}/>
</section>

</div>
<FloatingButton targetId="apply-section" />
<Footer /></>
    )}