import React, { useState, useEffect } from 'react';
import './FloatingButton.css';

const FloatingButton = ({ targetId }) => {
  const [isVisible, setIsVisible] = useState(false); // Set initial visibility to false

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting); // Invert the visibility logic
      },
      { threshold: 1 } // Adjust threshold as needed
    );

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [targetId]);

  const scrollToSection = () => {
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {isVisible && (
        <button className="floating-button" onClick={scrollToSection}>
          Vigyél a jelentkezéshez!
        </button>
      )}
    </>
  );
};

export default FloatingButton;
