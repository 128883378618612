import React, { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import './popup.css'

const Popup2 = () => {
  const [popupData, setPopupData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if the user has opted-out for today
    const hasOptedOutForToday = localStorage.getItem("popupOptOutDate") === new Date().toISOString().split("T")[0];

    // If the user has not opted-out for today, fetch and show the popup
    if (!hasOptedOutForToday) {
      sanityClient
        .fetch(
          `*[_type == "popup" && popupStartDate <= now() && popupEndDate >= now()] | order(popupStartDate asc) [0] {
            title,
            link,
            picture {
              asset->{_id, url}
            },
            description,
          }`
        )
        .then((data) => {
          setPopupData(data);
          openModal(); // Open the modal after fetching data
        })
        .catch(console.error);
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptOut = () => {
    // Set a local storage value to indicate the user has opted-out for today
    localStorage.setItem("popupOptOutDate", new Date().toISOString().split("T")[0]);
    closeModal();
  };

  // Check if there's no valid popup data to display
  if (!popupData) {
    return null;
  }

  return (


<Modal
  open={isModalOpen}
  onClose={closeModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  disableEnforceFocus={true}
>
  <div className="popup2-window">
    <div className="pop2-left">
      <button className="popup2-colse-button-2" onClick={handleOptOut}>
        Ma már ne mutasd többet.
      </button> 
      <button className="popup2-colse-button" onClick={closeModal}>
        <CloseIcon style={{ fontSize: '28px' }} />
      </button>
    </div>

    {popupData.picture && (
      <img className="popup2-image" src={popupData.picture.asset.url} alt={popupData.title} />
    )}

    <div className="pop2-right"> 
      {popupData.link && (
        <button>
        <a href={popupData.link} rel="noreferrer" target="_blank" className="popup2-open-button">
          Nézd meg az eseményeket <KeyboardDoubleArrowRightIcon style={{ fontSize: '28px' }} />
        </a></button>
      )}
    </div>
  </div>
</Modal>

   
  );
};

export default Popup2;




