import React from "react";
import Footer from "../Footer.js";
import "./Styles/Rental.css"
import EmailIcon from '@mui/icons-material/Email';
import ImageGallery from 'react-image-gallery';
import IMG1 from "./Img/IMG_8598.JPG"
import IMG2 from "./Img/IMG_1268.jpg"
import IMG3 from "./Img/IMG_3256.jpg"
import IMG4 from "./Img/IMG_7375.jpg"
import IMG5 from "./Img/IMG_7381.jpg"

import ReactGA from 'react-ga4';




const images = [
  {
    original: IMG1,
    thumbnail: IMG1,
  },
  {
    original: IMG2,
    thumbnail: IMG2,
  },
  {
    original: IMG3,
    thumbnail: IMG3,
  },
  {
    original: IMG4,
    thumbnail: IMG4,
  },
  {
    original: IMG5,
    thumbnail: IMG5,
  },
];

ReactGA.send({ hitType: "pageview", page: "/rental", title: "Berles" });

function Rental() {
  


  return (
    <div>
            
            <div className="book-main-container"> 
            <div className="side-title-back">
            <div className="title-text-container">
        <h1 className="side-title-text drop-in">Bérlés</h1>
    </div>
            </div>

        <div className="container">
          <div className="inside-container">
  <h2 className="rental-contact">Csatlakozz hozzánk és legyél tagja egy professzionális csapatnak, tarts órákat a frissen felújított termünkben!</h2>
  <div className="card-row">
    <div className="card">
      
      <h3>Mire alkalmas a terem?</h3>
<ul>
<li>Jóga</li>
<li>Intimtorna</li>
<li>Tánc</li>
<li>Meditáció</li>
<li>Tematikus programok</li>
<li>Csoportos mozgások</li>
<li>Kurzusok</li>
<li>Workshopok</li>
<li>Tanfolyamok</li>
</ul>

   </div>
    <div className="card">
      
      <h3>Mit bérelsz?</h3>
      <ul>
        <li>107 m2</li>
        <li>Parafa padló</li>
        <li>Padlófűtés</li>
        <li>2.8 m2 WC, bidével</li>
        <li>11.2 m2 öltöző</li>
        <li>14 m2 fogadó tér</li>
        <li>Ingyenes parkolás</li>
        <li>Belátás elleni fóliák</li>
        <li>Díszek, élő növények</li>
      </ul>
    </div>
    <div className="card">
      
      <h3>Eszközök</h3>
    <ul>
      <li>Bodhi jógaszőnyegek</li>
      <li>Puha plédek</li>
      <li>Hengerpárnák</li>
      <li>Jógatéglák</li>
      <li>Jógablokk</li>
      <li>Heveder</li>
    </ul>
    </div>

    <div className="card">
      <h3>Milyen időbeosztásban foglalhatsz?</h3>
      <ul>
        <li>Alkalmi</li>
        <li>Állandó jelleggel</li>
      </ul>
    </div>

  </div>
          <div className="rental-contact">
            <p>Részletekért keress az alábbi elérhetőségen:</p>
            <p><a className="socialfooter" href="mailto:info@liafit.hu"><EmailIcon></EmailIcon>info@liafit.hu</a></p>
            </div>
            <div className="gallery">
            <ImageGallery items={images} />
            </div>

</div>
            
</div>

      </div>
      <Footer />
    </div>
  );
}

export default Rental;