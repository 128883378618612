import React, { useState, useEffect } from 'react';
import Switch from './Components/Switch/Switch.js';

const ThemeToggle = () => {
  // Initialize isDarkMode with the value from localStorage or default to 'light'
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedMode = localStorage.getItem('colorMode');
    return storedMode === 'dark';
  });

  useEffect(() => {
    const root = window.document.documentElement;
    const body = window.document.body;

    if (isDarkMode) {
      root.classList.add('dark-mode');
      body.classList.add('dark-mode');
      localStorage.setItem('colorMode', 'dark');
    } else {
      root.classList.remove('dark-mode');
      body.classList.remove('dark-mode');
      localStorage.setItem('colorMode', 'light');
    }
  }, [isDarkMode]);

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <Switch checked={isDarkMode} onChange={handleToggle} />
  );
};

export default ThemeToggle;
