import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './Newsletter.css';


const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here, e.g., sending data to your server
  };

  useEffect(() => {
    // Include Mailchimp scripts
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    document.head.appendChild(script);

    return () => {
      // Cleanup on component unmount
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="subscribe-container">
      <div className="newsletter-color-filter">
        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form
              action="https://liafit.us21.list-manage.com/subscribe/post?u=000b95b9797d1aad4cb8ca180&amp;id=f77231f169&amp;f_id=007beee6f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              onSubmit={handleSubmit}
            >
              <div id="mc_embed_signup_scroll">
                <h2>Iratkozz fel a hírlevélre</h2>
                <div className="indicates-required">
                  <span className="asterisk">*</span> -al jelölt mező kitöltése kötelező
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email cím <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{color:'black', fontWeight:'500'}}
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-FNAME">Keresztnév </label>
                  <input
                    type="text"
                    name="FNAME"
                    className="text"
                    id="mce-FNAME"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{color:'black', fontWeight:'500'}}
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-LNAME">Vezetéknév </label>
                  <input
                    type="text"
                    name="LNAME"
                    className="text"
                    id="mce-LNAME"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={{color:'black', fontWeight:'500'}}
                  />
                </div>
                <div className="mc-field-group mc-checkbox">
                  <input
                    type="checkbox"
                    id="mce-privacy-checkbox"
                    required=""
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <label htmlFor="mce-privacy-checkbox">
                    Elfogadom az adatvédelmi irányelveket.<span className="asterisk">*</span>
                  </label>
                </div>
                <div id="mce-responses" className="clear foot">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input
                    type="text"
                    name="b_000b95b9797d1aad4cb8ca180_f77231f169"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="mailchimp-button"
                      value="Feliratkozás"
                      disabled={!isChecked} // Disable if checkbox is not checked
                    />
                    <p style={{ margin: '0px auto' }}>
                      <a
                        href="http://eepurl.com/iJnLqE"
                        title="Mailchimp - email marketing made easy and fun"
                      >
                        <span style={{ display: 'inline-block', background: 'transparent' }}>
                          <img
                            className="refferal_badge"
                            src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                            alt="Intuit Mailchimp"
                            style={{ width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center' }}
                          />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
