import React, { useEffect, useState } from 'react';
import '../../nav.css';
import ReactGA from 'react-ga4';
const Switch = ({ checked, onChange }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 65) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggle = () => {
    onChange(!checked);
      // Track the click event.
      ReactGA.send({
        hitType: 'event',
        eventCategory: 'Ejszakai mod',
        eventAction: 'Ejszakai mod gomb',
      });
  };

  return (
    <button
      className={`switch ${checked ? 'on' : 'off'}`}
      onClick={handleToggle}
      style={{
        top: isScrolled ? '15px' : '15px',
        transition: 'top 0.2s ease',
      }}
    >
      <p className="slider" />
    </button>
  );
};

export default Switch;
