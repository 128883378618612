import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../../../client.js"; // Importing the Sanity client
import "./Events.css"; // Importing the CSS file for this component
import Footer from "../Footer.js"; // Importing the Footer component
import ReactGA from 'react-ga4';

import { Modal} from "@mui/material";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ReactMarkdown from "react-markdown";


ReactGA.send({ hitType: "pageview", page: "/events", title: "Esemenyek" });

export default function Events() {
  const [eventsData, setEventsData] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { eventId } = useParams(); // Get the eventId from the URL params


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"] | order(startdate asc) {
          _id,
          title,
          deadline,
          startdate,
          enddate,
          place,
          description,
          formattedDescription,
          eventsType,
          link,
          tags,
          mainImage {
            asset->{_id, url}
          }
        }`
      )
      .then((data) => {
        const filteredData = data.filter((event) => {
          const eventDate = new Date(event.startdate);
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          return eventDate >= now;
        });
        setEventsData(filteredData);

        if (eventId) {
          const selected = filteredData.find((event) => event._id === eventId);
          if (selected) {
            setSelectedEvent(selected);
          }
        }

      })
      .catch(console.error);
  }, [eventId]);

  const handleOpenModal = (eventOrId) => {
    if (typeof eventOrId === "object") {
      setSelectedEvent(eventOrId);
    } else {
      // Find the event by ID
      const selected = eventsData.find((event) => event._id === eventOrId);
      if (selected) {
        setSelectedEvent(selected);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const renderMarkdownBlocks = (blocks) => {
    return blocks.map((block, index) => {
      if (block._type === "block") {
        return (
          <ReactMarkdown key={index}>
            {block.children.map((child) => child.text).join("")}
          </ReactMarkdown>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="events-main-container">
        <div className="side-title-back">
          <div className="title-text-container">
            <h1 className="side-title-text drop-in">Események</h1>
          </div>
        </div>


        <div className="cards-container">
          {eventsData && eventsData.length > 0 ? (
            eventsData.map((events, index) => (
              <div
                data-date={
                  events.startdate &&
                  new Date(events.startdate).toLocaleString('hu-HU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }
                className="cards"
                key={index}
              >
                {events.mainImage && (
                  <img
                    className="events-title-image"
                    src={events.mainImage.asset.url}
                    alt=""
                  />
                )}
                <div className="cards-textbox">

                <Link to={`/events/${events._id}`}> {/* Add a link to each event with its ID in the URL */}
                  <button onClick={() => handleOpenModal(events)}>
                      <h3>
                        {events.title}
                        </h3>
                  </button>
                </Link>


              {events.startdate && (
                <span id="startdateid" className="datespace">
                  <strong>Kezdés</strong>:{' '}
                  {new Date(events.startdate).toLocaleString('hu-HU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              )}
 
                      <Link to={`/events/${events._id}`}> {/* Add a link to each event with its ID in the URL */}
                  <button className="read-more-blog" onClick={() => handleOpenModal(events)}>
                   
                      Olvass Tovább
                     
                  </button>
                </Link>

                    </div>
              </div>
            ))
          ) : (
            <div className="no-posts-message" ><p >Jelenleg nincsenek új események</p></div>
            
          )}
        </div>



{/* Modal */}
<Modal
  open={selectedEvent !== null}
  onClose={handleCloseModal}
  aria-labelledby="class-sub-modal"
  aria-describedby="class-details"
>
  <Fade in={selectedEvent !== null}>
    <Box className="instructor-modal">
      <button className="modal-close-button-cards" onClick={handleCloseModal}>
        <ClearOutlinedIcon />
      </button>

      {selectedEvent && (
        <>
          <div className="instructor-sub-side-modal">
            {selectedEvent.mainImage && (
              <img
                className="events-title-image"
                src={selectedEvent.mainImage.asset.url}
                alt=""
              />
            )}
<div className="events-modal-textbox">
          <h3 className="events-modal-title"> {selectedEvent.title}</h3>
            {selectedEvent.link && (
              <div className="events-link">
                <a
                  className="link"
                  href={selectedEvent.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >Link
                  <LinkIcon />
                </a>
              </div>
            )}

            {selectedEvent.place && (
              <p className="events-rectangles-p"><span className="events-rectangles-span">Hely: </span><span className="events-rectangles-span">
              {selectedEvent.place || ''}
            </span></p>
              

            )}

            {selectedEvent.deadline && (
<p className="events-rectangles-p"><span className="events-rectangles-span">Jelentkezési határidő</span>
              <span className="events-rectangles-span">
                
                {new Date(selectedEvent.deadline).toLocaleString('hu-HU', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
              </p>
            )}

            {selectedEvent.startdate && (
<p className="events-rectangles-p">
<span className="events-rectangles-span">Kezdés</span>
              <span id="startdateid" className="events-rectangles-span">
                
                {new Date(selectedEvent.startdate).toLocaleString('hu-HU', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span></p>


            )}

            {selectedEvent.enddate && (
<p className="events-rectangles-p"><span className="events-rectangles-span">Befejezés</span>
              <span className="events-rectangles-span">
               
                {new Date(selectedEvent.enddate).toLocaleString('hu-HU', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span></p>

            )}
            </div>
          </div>

         

          <div className="instructor-main-side-modal">
                  {selectedEvent.formattedDescription && renderMarkdownBlocks(selectedEvent.formattedDescription)}
                </div>

        </>
      )}
    </Box>
  </Fade>
</Modal>


      </div>
      <Footer />
    </>
  );
}