
import TelephoneIcon from '@mui/icons-material/CallOutlined';
import "./ContactUs.css"
import { SocialIcon } from 'react-social-icons';
import EmailIcon from '@mui/icons-material/EmailOutlined';


import { ReactComponent as MapSvg } from './map.svg';
import { ReactComponent as CallSvg } from './call.svg';
import { ReactComponent as SocialMediaSvg } from './social.svg';

import ReactGA from 'react-ga4';


ReactGA.send({ hitType: "pageview", page: "/contactus", title: "Kezdolap" });


export default function Book () {



return (

<div id="contactusid" className="home-main-container-contact">

    <div className="contactus-section">
            <div className="map">
            <MapSvg className="line-art" />
            </div>
             <div className="map">
            <p className="contactus-text "><a className="socialfooter" href="https://goo.gl/maps/LkqhVxXhma38usMe6 " target="_blank" rel="noreferrer">Itt találsz meg a térképen</a></p>
            </div> 
            <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.873782046179!2d17.284170117443843!3d47.8807709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476b8966c3a64ffb%3A0x9998f887224970f6!2sLia%20Fit!5e0!3m2!1shu!2shu!4v1675006725964!5m2!1shu!2shu" 
                  style={{
                    border:0,
                  }}
                  width="100%" height="400"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe>
            </div>
    </div>

    <div className="contactus-section">
            <div className="phone">
            <CallSvg className="line-art" />
            </div>
             <div className="phone">
                <p className="contactus-text ">Elérhetőségeink</p>
            </div> 
            <div className="phone">
                <div className="circle">
                <div className="inner-circle">
                    <p><a className="contacticons" href="mailto:info@liafit.hu"><EmailIcon></EmailIcon></a></p>
                    <p><a className="contacticons" href="mailto:info@liafit.hu">info@liafit.hu</a></p></div>
                </div>
                <div className="circle">
                    <div className="inner-circle">
                    <p><a href="tel:+36703836041"><TelephoneIcon></TelephoneIcon></a></p>
                    <p><a href="tel:+36703836041">+36703836041</a></p></div>
                </div>
            </div>
    </div>

    <div className="contactus-section">
            <div className="social">
            <SocialMediaSvg className="line-art" />
            </div>
             <div className="social">
                <p className="contactus-text ">Kövess minket!</p>
            </div> 
            <div className="social">
                <div className="contactus-social-icons">
                    <SocialIcon style={{transition:"0.5s", width:"80px", height:"80px"}} url="https://www.facebook.com/julialaszlo.liafit/" />
                    <SocialIcon style={{transition:"0.5s", width:"80px", height:"80px"}} url="https://www.instagram.com/liafit_movar/" />
                    <SocialIcon style={{transition:"0.5s", width:"80px", height:"80px"}} url="https://www.tiktok.com/@liafit9200?_t=8eHpUYzDTUw&_r=1" />
                    </div>
            </div>
    </div>

</div> 
);
                }