import "./Styles/Blog.css"
import sanityClient from "../../../client.js";
import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { makeStyles } from '@mui/styles';
import Footer from "../Footer.js";

import ReactGA from 'react-ga4';

ReactGA.send({ hitType: "pageview", page: "/blog", title: "Blog" });




const useStyles = makeStyles({
  rotatedIcon: {
    transform: 'rotate(180deg)',
  },
});

export default function Blog () {
    const [ascending, setAscending] = useState(false);
    const [postData, setPostData] = useState(null);
  
    const toggleOrder = () => {
      setAscending(!ascending);
      fetchPosts(!ascending);
    };
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (event) => setSearchQuery(event.target.value);
    
    const fetchPosts = (isAscending, searchQuery) => {
      const orderClause = isAscending ? 'asc' : 'desc';
      const query = `*[_type == "post" ${
        searchQuery
          ? `&& (title match "${searchQuery}*" || author->name match "${searchQuery}*")`
          : ''
      }] | order(publishedAt ${orderClause}) {
        title,
        publishedAt,
        slug,
        extract,
        mainImage {
          asset->{_id, url},
          alt
        },
        body,
        "name": author->name,
        "authorImage": author->image{asset->{_id, url}}
      }`;
    
      sanityClient.fetch(query)
        .then(data => setPostData(data))
        .catch(err => console.error(err));
    };
    
  
    useEffect(() => {
      fetchPosts(ascending);
    }, [ascending]);

    const classes = useStyles();
    
    



    return (
<>
<div className="blog-main-container">

<div className="side-title-back">
    <div className="title-text-container">
        <h1 className="side-title-text drop-in">Blog</h1>
    </div>
  </div>

<div className="blog-options">

  <input className="searchbar"
    type="text"
    value={searchQuery}
    placeholder="Keresés"
    onChange={handleSearchChange}
  />
  <button className="openbutton" onClick={toggleOrder}>
    {ascending ? "Rendezés: csökkenő" : "Rendezés: növekvő"}{" "}
    {ascending ? (
      <FilterListOutlinedIcon />
    ) : (
      <FilterListOutlinedIcon className={classes.rotatedIcon} />
    )}
  </button>
</div>

<div id="list" className="blog-container">
{postData && postData.length > 0 ? (
            postData
              .filter(
                (post) =>
                  (post.title && post.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                  (post.name && post.name.toLowerCase().includes(searchQuery.toLowerCase()))
              )
              .map((post, index) => (
        <div
          key={index}
          data-date={post.publishedAt && new Date(post.publishedAt).toLocaleDateString()}
          className="blog-box drop-in"
        >
          <NavLink to={"/blog/" + post.slug.current} key={post.slug.current}>
            {post.mainImage && (
              <img
                className="blog-cover-image drop-in-2"
                src={post.mainImage.asset.url}
                alt={post.mainImage.alt}
              />
            )}
          </NavLink>

          <div className="blog-cover-text drop-in-2">
            <h1 className="drop-in">
              <NavLink to={"/blog/" + post.slug.current} key={post.slug.current}>
                {post.title}
              </NavLink>
            </h1>
            <p className="blog-date drop-in">
              {post.publishedAt && new Date(post.publishedAt).toLocaleDateString()}
            </p>
            <p className="blog-extract drop-in-2">
              {post.extract}
              <NavLink
                className="read-more-blog"
                to={"/blog/" + post.slug.current}
                key={post.slug.current}
              >
                Olvass tovább...
              </NavLink>
            </p>
            <div className="blog-meta">
              {post.name && (
                <p className="blog-author">
                  Szerző: <strong>{post.name}</strong>
                </p>
              )}
              {post.authorImage && (
                <img
                  className="blog-author-image"
                  alt={post.name}
                  src={post.authorImage.asset.url}
                />
              )}
            </div>
          </div>
        </div>
      ))
      ) : (
        <div className="no-posts-message">
          <p>Még nincsenek blog bejegyzések</p>
        </div>
      )}
    </div>
  </div>
  <Footer />
</>
);
}