import React from "react";
import Footer from "../Footer.js";
import './uniqueclasses.css'
import Pic1 from './Media/IMG_0814.jpg'
import Pic2 from './Media/IMG_0670-2(1).jpg'
import Pic3 from './Media/IMG_0678-2(1).jpg'
import InstructorSelected from "../InstructorSelected.js";
import ReactGA from 'react-ga4';

ReactGA.send({ hitType: "pageview", page: "/personaltraining", title: "Szemelyi Edzes" });
function PersonalTraining() {
  
  return (
    <div>
            
            <div className="book-main-container"> 

<div className="unique-class-title">
<div className="unique-class-title-left">

<h1>Személyi edzés Martinnal</h1>
    <div className="personal-data">
        <a href = "mailto:smartartinhealth@gmail.com">smartartinhealth@gmail.com</a>
        <a href="tel:+36309243073">+36 30 924 3073</a>
    </div>

</div>

{/*     <div className="unique-class-title-right">

<img src={Profil3} />

    </div> */}

</div>


        <div className="container">
          <div className="inside-container">
 

<div className="class-description">




<div className="class-description-text">
<h2 className="class-description-h2">Az óráról:</h2>
<p className="class-description-p">
Az állapotfelmérést és célmeghatározást követően személyre szabott edzésprogramot állítok össze.<br></br>
Holisztikusan tekintek az egészségre és nem gondolom, hogy pusztán mozgással minden orvosolható, így az együttműködésünk alatt mindenképp foglalkozunk az életmóddal és táplálkozással is. <br></br>
Óráimon eleinte a hétköznapokból eredő tartáshibák korrigálásával és a helyes mozgásminták megtanulásával kezdünk, hiszen ezek minősége alapozza meg a további edzésmunkát is. <br></br>
A legfontosabbnak az egészséget tartom és főként funkcionális szemlélettel gondolok a mozgásra. <br></br>
A tartásjavításon és mozgástanuláson túl természetesen bátran kereshettek különféle célokkal is, mint például fogyás, alakformálás, izomépítés, teljesítményfokozás, egészséges szokások kialakítása, stb.
</p>
</div>

<div className="class-description-pictures">
<img src={Pic1}/>
<img src={Pic2}/>
<img src={Pic3}/>

</div>

</div>

<div className="introduction">

</div>

  </div>      
</div>
<InstructorSelected  filteredNames={["Stanka Martin"]} />
      </div>
      <Footer />
    </div>
  );
}

export default PersonalTraining;