import { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from 'react-icons/fa';
import "./nav.css";
import { NavLink, useLocation } from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ThemeToggle from '../src/ThemeToggle.js';
import {ReactComponent as Lialogo} from './liafit_black_white.svg'

function Navbar() {
  const navRef = useRef();
  const toggleButtonRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const [isTransparent, setIsTransparent] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setIsTransparent(true);
      setIsScrolled(true);
    } else {
      setIsTransparent(false);
      setIsScrolled(false);
    }
  };

  const [isUniqueClassesDropdownOpen, setUniqueClassesDropdownOpen] = useState(false);

  const toggleUniqueClassesDropdown = () => {
    setUniqueClassesDropdownOpen(!isUniqueClassesDropdownOpen);
  };

  const location = useLocation();
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dropdownRef = useRef();


  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && 
          toggleButtonRef.current && !toggleButtonRef.current.contains(event.target)) {
        setUniqueClassesDropdownOpen(false);
      }
    }

  
    if (isUniqueClassesDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isUniqueClassesDropdownOpen]);
  


  const isMobileView = windowWidth < 1180;

  return (
    <header id="navbar" className={isTransparent ? "transparent" : ""}>
      <div className="navbar-sub">
        <button className={`left-logo ${isScrolled ? "isScrolled" : ""}`} style={{ visibility: location.pathname.startsWith("/about") ? "hidden" : "visible" }}>
          <NavLink className="logo-container" to="/">

           {/*  <img src={AltLogo} alt="alternativelogo" style={{ height: isIOSDevice ? "40px" : "" }} /> */}
            <Lialogo className="svg-logo-header"/>

          </NavLink>
        </button>

        <nav ref={navRef}>
     
          <button onClick={showNavbar}>
            <NavLink to="/">Kezdőlap</NavLink>
          </button>
          <button onClick={showNavbar}>
            <NavLink to="/about">Rólam</NavLink>
          </button>
          <button onClick={showNavbar}>
            <NavLink to="/blog">Blog</NavLink>
          </button>
          <button onClick={showNavbar}>
            <NavLink to="/rental">Bérlés</NavLink>
          </button>
          <button onClick={showNavbar}>
            <NavLink to="/contactus">Kapcsolat</NavLink>
          </button>

          <button onClick={showNavbar}>
            <NavLink to="/lifestyle">Életmódváltás</NavLink>
          </button>
        

          
          
  {isMobileView ? (
    // Mobile view specific rendering
    <>
        <button className="mobile-button-smt" onClick={toggleUniqueClassesDropdown}><ExpandLessIcon className={`expand-icon ${isUniqueClassesDropdownOpen ? 'twisted' : ''}`} /><span>Egyéni foglalkozások</span></button>
        <NavLink className={`dropdown-content-navlink ${isUniqueClassesDropdownOpen ? 'dropdown-button-opened' : ''}`} onClick={() => { showNavbar(); toggleUniqueClassesDropdown(); }} to="/uniqueclasses/personaltraining">Személyi edzés Martinnal</NavLink>
        <NavLink className={`dropdown-content-navlink ${isUniqueClassesDropdownOpen ? 'dropdown-button-opened' : ''}`} onClick={() => { showNavbar(); toggleUniqueClassesDropdown(); }} to="/uniqueclasses/humankinezilogia">Humánkineziológia & Jóga Liával</NavLink>
    </>
  ) : (
    // Non-mobile view specific rendering
    <div className="dropdown-wrapper">
      <button ref={toggleButtonRef} className="nav-button-smt"  onClick={toggleUniqueClassesDropdown}><ExpandLessIcon className={`expand-icon-nav ${isUniqueClassesDropdownOpen ? 'twisted' : ''}`} /><span>Egyéni foglalkozások</span></button>
      {isUniqueClassesDropdownOpen && (
        <div ref={dropdownRef} className={`dropdown-content ${isUniqueClassesDropdownOpen ? 'dropdown-opened' : ''}`}>
          <NavLink onClick={() => { toggleUniqueClassesDropdown(); }} to="/uniqueclasses/personaltraining">Személyi edzés Martinnal</NavLink>
          <NavLink onClick={() => { toggleUniqueClassesDropdown(); }} to="/uniqueclasses/humankinezilogia">Humánkineziológia & Jóga Liával</NavLink>
        </div>
      )}
    </div>
  )}




          <button onClick={showNavbar}>
            <NavLink to="/events">Események</NavLink>
          </button>

          <button onClick={showNavbar}>
            <NavLink to="/book">Foglalás</NavLink>
          </button>
          


          <ThemeToggle className={isScrolled ? "isScrolled" : "notScrolled"} />
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </header>
  );
}

export default Navbar;
