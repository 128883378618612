import ReactGA from 'react-ga4';

ReactGA.send({ hitType: "pageview", page: "/datapolicy", title: "Datapolicy" });


export default function DataPolicy () {
    return (
<>
<div className="data-policy">
    <div className="data-policy-container"> 
<h1 className="data-title">Adatvédelmi irányelvek</h1>
<p>Utolsó frissítés: 2023. november 12.</p>
<p>Ez az adatvédelmi irányelv leírja az Ön adatainak gyűjtésére, felhasználására és közzétételére vonatkozó szabályzatainkat és eljárásainkat, amikor a Szolgáltatást használja, és tájékoztatja az adatvédelmi jogairól és arról, hogyan védi Önt a törvény.</p>
<p>Személyes adatait a Szolgáltatás nyújtására és javítására használjuk. A Szolgáltatás használatával Ön beleegyezik az információk ezen adatvédelmi irányelvnek megfelelő gyűjtésébe és felhasználásába. Ezt az adatvédelmi irányelvet az <a href="https://www.privacypolicies.com/privacy-policy-generator/" rel="noreferrer" target="_blank">Adatvédelmi Irányelvek Generátor</a> segítségével hoztuk létre.</p>
<h1 className="data-title">Értelmezés és meghatározások</h1>
<p>Azoknak a szavaknak, amelyek kezdőbetűje nagybetűvel van írva, az alábbi feltételek mellett meghatározott jelentésekkel bírnak. A következő meghatározásoknak ugyanaz a jelentése van, függetlenül attól, hogy egyes vagy többes számban szerepelnek-e.</p>
<h3>Meghatározások</h3>
<p>Ezen adatvédelmi irányelv céljából:</p>
<ul>
<li>
<p><strong>Fiók</strong> egy Ön számára létrehozott egyedi fiókot jelent a Szolgáltatásunkhoz vagy annak egyes részeihez való hozzáféréshez.</p>
</li>
<li>
<p><strong>Társult vállalkozás</strong> olyan entitást jelent, amely egy fél felett rendelkezik, irányít alá van rendelve vagy közös irányítás alatt áll vele, ahol a "kontroll" azt jelenti, hogy a részvények, tulajdoni érdeklődések vagy más, a vezetők vagy más irányító hatóság választására jogosult értékpapírok 50%-a vagy annál többje van birtokában.</p>
</li>
<li>
<p><strong>Vállalat</strong> (a jelen Megállapodásban "a Vállalat", "Mi", "Minket" vagy "Miénk" néven hivatkozva) a László Júlia E.V., Mosonmagyaróvár, Szellőrózsa u. 33-37, 9200-ra vonatkozik.</p>
</li>
<li>
<p><strong>Sütik</strong> kis fájlok, amelyeket egy weboldal helyez el a számítógépén, mobil eszközén vagy bármilyen más eszközön, tartalmazzák a böngészési előzményeit az adott weboldalon, többek között számos egyéb felhasználással.</p>
</li>
<li>
<p><strong>Ország</strong> Magyarországra vonatkozik.</p>
</li>
<li>
<p><strong>Eszköz</strong> bármely eszközt jelent, amely képes hozzáférni a Szolgáltatáshoz, például számítógép, mobiltelefon vagy digitális tábla.</p>
</li>
<li>
<p><strong>Személyes adatok</strong> bármely olyan információ, amely egy azonosított vagy azonosítható egyénre vonatkozik.</p>
</li>
<li>
<p><strong>Szolgáltatás</strong> a Weboldalra vonatkozik.</p>
</li>
<li>
<p><strong>Szolgáltató</strong> bármely természetes vagy jogi személyt jelent, aki a Vállalat nevében adatokat dolgoz fel. Harmadik felek által működtetett cégekre vagy személyekre vonatkozik, akiket a Vállalat alkalmaz a Szolgáltatás megkönnyítésére, annak nyújtására a Vállalat nevében, a Szolgáltatáshoz kapcsolódó szolgáltatások elvégzésére vagy a Vállalat segítésére a Szolgáltatás használatának elemzésében.</p>
</li>
<li>
<p><strong>Használati adatok</strong> automatikusan gyűjtött adatokat jelentenek, amelyek a Szolgáltatás használatából vagy magából a Szolgáltatás infrastruktúrájából származnak (például egy oldallátogatás időtartama).</p>
</li>
<li>
<p><strong>Weboldal</strong> a LIA FIT-re vonatkozik, amely elérhető a következő címen: <a href="http://www.liafit.hu" rel="noreferrer" target="_blank">http://www.liafit.hu</a></p>
</li>
<li>
<p><strong>Ön</strong> azt az egyént jelenti, aki hozzáfér vagy használja a Szolgáltatást, vagy azt a céget, vagy más jogi entitást, amelynek nevében az adott egyén hozzáfér vagy használja a Szolgáltatást, amennyiben ez alkalmazható.</p>
</li>
</ul>
<h2>Személyes adatainak gyűjtése és felhasználása</h2>
<h3>A gyűjtött adattípusok</h3>
<h4>Személyes adatok</h4>
<p>Szolgáltatásunk használata közben kérhetjük Öntől, hogy bizonyos személyesen azonosítható információkat adjon meg, amelyek felhasználhatók az Önnel való kapcsolatfelvételhez vagy azonosításához. A személyesen azonosítható információk közé tartozhat, de nem korlátozódik ezekre:</p>
<ul>
<li>
<p>E-mail cím</p>
</li>
<li>
<p>Keresztnév és vezetéknév</p>
</li>
<li>
<p>Telefonszám</p>
</li>
<li>
<p>Használati adatok</p>
</li>
</ul>
<h1 className="data-title">Használati adatok</h1>
<p>A használati adatok automatikusan gyűlnek össze a Szolgáltatás használatakor.</p>
<p>A használati adatok magukban foglalhatják például az Ön eszközének internet protokoll címét (pl. IP-cím), böngésző típusát, böngésző verzióját, a látogatott szolgáltatásunk oldalait, a látogatás időpontját és dátumát, azokon az oldalakon töltött időt, egyedi eszközazonosítókat és egyéb diagnosztikai adatokat.</p>
<p>Amikor mobil eszközön keresztül fér hozzá a Szolgáltatáshoz, automatikusan gyűjthetünk bizonyos információkat, beleértve, de nem kizárólagosan, a használt mobil eszköztípusát, mobil eszközének egyedi azonosítóját, mobil eszközének IP-címét, mobil operációs rendszerét, a használt mobil internetes böngésző típusát, egyedi eszközazonosítókat és egyéb diagnosztikai adatokat.</p>
<p>Szintén gyűjthetünk olyan információkat, amelyeket a böngészője küld, amikor meglátogatja a Szolgáltatásunkat, vagy amikor mobil eszközön keresztül fér hozzá a Szolgáltatáshoz.</p>
<h1 className="data-title">Követési technológiák és sütik</h1>
<p>Sütiket és hasonló követési technológiákat használunk a Szolgáltatásunkon végzett tevékenység nyomon követésére és bizonyos információk tárolására. A használt követési technológiák jelzők, címkék és szkriptek lehetnek az információk gyűjtésére és nyomon követésére, valamint Szolgáltatásunk javítására és elemzésére. Az általunk használt technológiák közé tartozhatnak:</p>
<ul>
<li><strong>Sütik vagy böngésző sütik.</strong> A süti egy kis fájl, amelyet az eszközére helyeznek. Utasíthatja böngészőjét, hogy utasítsa el az összes Sütit vagy jelezze, amikor egy Sütit küldenek. Ha azonban nem fogadja el a Sütiket, előfordulhat, hogy Szolgáltatásunk bizonyos részeit nem tudja használni. Hacsak nem állította be böngészőjét úgy, hogy elutasítsa a Sütiket, Szolgáltatásunk használhat Sütiket.</li>
<li><strong>Webjelzők.</strong> Szolgáltatásunk bizonyos részei és e-maileink kis elektronikus fájlokat tartalmazhatnak, amelyeket webjelzőknek is neveznek (más néven tisztán gifek, pixelcímkék és egy-pixel gifek) és amelyek lehetővé teszik a Vállalat számára például a látogatók számának megszámlálását, akik meglátogatták azokat az oldalakat vagy megnyitottak egy e-mailt, valamint más kapcsolódó weboldal statisztikákhoz (például egy bizonyos szakasz népszerűségének rögzítése és a rendszer és a szerver integritásának ellenőrzése).</li>
</ul>
<p>A Sütik lehetnek "Állandó" vagy "Munkamenet" Sütik. Az Állandó Sütik az Ön személyi számítógépén vagy mobil eszközén maradnak, amikor offline állapotba kerül, míg a Munkamenet Sütik törlődnek, amint bezárja webböngészőjét. További információk a sütikről az <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" rel="noreferrer" target="_blank">Adatvédelmi Irányelvek weboldal</a> cikkében találhatók.</p>
<p>Mind Állandó, mind Munkamenet Sütiket használunk az alábbi célokra:</p>
<ul>
<li>
<p><strong>Szükséges / Lényeges Sütik</strong></p>
<p>Típus: Munkamenet Sütik</p>
<p>Kezeli: Mi</p>
<p>Cél: Ezek a Sütik elengedhetetlenek ahhoz, hogy Önnek a Weboldalon keresztül elérhetőszolgáltatásokat nyújthassunk, és lehetővé tegyék azok néhány funkciójának használatát. Segítenek a felhasználók hitelesítésében és a felhasználói fiókok csalárd használatának megakadályozásában. Ezek nélkül a Sütik nélkül az Ön által kért szolgáltatásokat nem lehet biztosítani, és csak ezeket a Sütiket használjuk az Önnek nyújtott szolgáltatásokhoz.</p>
</li>
<li>
<p><strong>Sütikre vonatkozó szabályzat / Elfogadási értesítés Sütik</strong></p>
<p>Típus: Állandó Sütik</p>
<p>Kezeli: Mi</p>
<p>Cél: Ezek a Sütik azonosítják, hogy a felhasználók elfogadták-e a sütik használatát a Weboldalon.</p>
</li>
<li>
<p><strong>Funkcionalitás Sütik</strong></p>
<p>Típus: Állandó Sütik</p>
<p>Kezeli: Mi</p>
<p>Cél: Ezek a Sütik lehetővé teszik számunkra, hogy megjegyezzük azokat a választásokat, amelyeket Ön a Weboldal használatakor tesz, például a bejelentkezési adatainak vagy nyelvi preferenciáinak megjegyzése. Ezeknek a Sütiknek a célja, hogy személyesebb élményt nyújtsanak Önnek, és elkerüljék, hogy minden alkalommal, amikor a Weboldalt használja, újra be kelljen állítania preferenciáit.</p>
</li>
</ul>
<p>További információk a weboldalunkon használt sütikről és a sütikkel kapcsolatos választásairól a Sütikre vonatkozó irányelveinkben vagy Adatvédelmi Irányelveink Sütik szakaszában találhatók.</p>
<h1 className="data-title">Személyes adatainak felhasználása</h1>
<p>A Vállalat a következő célokra használhatja a Személyes Adatokat:</p>
<ul>
<li>
<p><strong>A Szolgáltatás nyújtása és karbantartása</strong>, beleértve a Szolgáltatás használatának monitorozását.</p>
</li>
<li>
<p><strong>Fiókjának kezelése:</strong> a Szolgáltatás felhasználójaként történő regisztrációjának kezelése. Az általad megadott Személyes Adatok hozzáférést biztosíthatnak a Szolgáltatás különböző funkcionalitásaihoz, amelyek regisztrált felhasználóként Önnek elérhetőek.</p>
</li>
<li>
<p><strong>Szerződés teljesítése:</strong> az Ön által vásárolt termékek, cikkek vagy szolgáltatások, vagy a Szolgáltatásunkon keresztül velünk kötött bármely más szerződés megkötésének, betartásának és teljesítésének fejlesztése.</p>
</li>
<li>
<p><strong>Kapcsolatfelvétel Önnel:</strong> E-mailben, telefonhívásokon, SMS-en vagy más, elektronikus kommunikáció egyenértékű formáiban, például mobilalkalmazások push értesítésein keresztül történő kapcsolatfelvétel az Ön által már megvásárolt vagy érdeklődött árukhoz, szolgáltatásokhoz és eseményekhez hasonló egyéb termékekkel, szolgáltatásokkal vagy eseményekkel kapcsolatos frissítésekkel vagy tájékoztató kommunikációkkal kapcsolatosan, beleértve a biztonsági frissítéseket, amikor azok végrehajtásához szükségesek vagy ésszerűek.</p>
</li>
<li>
<p><strong>Hírekkel, különleges ajánlatokkal és általános információkkal ellátni Önt</strong> más, a már megvásárolt vagy érdeklődött termékekhez, szolgáltatásokhoz és eseményekhez hasonló áruk, szolgáltatások és események kapcsán, kivéve, ha Ön úgy döntött, hogy nem kíván ilyen információkat kapni.</p>
</li>
<li>
<p><strong>Kéréseinek kezelése:</strong> Kéréseinek kezelése és megválaszolása.</p>
</li>
<li>
<p><strong>Üzleti átadások:</strong> Információinak felhasználása egyesülés, eladás, átszervezés, feloszlatás vagy hasonló eljárás keretében, amikor a Személyes Adatok a Vállalat általunk kezelt Szolgáltatás felhasználóinak egy része vagy egésze lehetnek.</p>
</li>
<li>
<p><strong>Egyéb célok:</strong> Információinak felhasználása más célokra, például adatelemzés, felhasználási trendek azonosítása, promóciós kampányaink hatékonyságának megállapítása és Szolgáltatásunk, termékeink, szolgáltatásaink, marketingünk és az Ön tapasztalatainak értékelése és javítása.</p>
</li>
</ul>
<p>Személyes adatait a következő helyzetekben oszthatjuk meg:</p>
<ul>
<li><strong>Szolgáltatókkal:</strong> Személyes adatait Szolgáltatóknak adhatjuk át a Szolgáltatásunk használatának monitorozására és elemzésére, valamint az Önnel való kapcsolatfelvétel céljából.</li>
<li><strong>Üzleti átadások:</strong> Személyes adatait átadhatjuk vagy továbbíthatjuk üzleti tárgyalások során, vagy bármely egyesülés, Vállalat eszközeinek eladása, finanszírozás vagy üzleti részünk egészének vagy egy részének más vállalatnak történő átadása kapcsán.</li>
<li><strong>Társult vállalkozásokkal:</strong> Információit Társult vállalkozásokkal oszthatjuk meg, ebben az esetben követelmény, hogy ezek a társult vállalkozások betartsák ezt az Adatvédelmi Irányelvet. A társult vállalkozások közé tartoznak szülővállalatunk és egyéb leányvállalataink, közös vállalkozási partnereink vagy más, velünk közös irányítás alatt álló vállalatok.</li>
<li><strong>Üzleti partnerekkel:</strong> Információit Üzleti partnereinkkel oszthatjuk meg, hogy bizonyos termékeket, szolgáltatásokat vagy promóciókat kínálhassunk Önnek.</li>
<li><strong>Más felhasználókkal:</strong> amikor személyes információkat oszt meg vagy más módon lép interakcióba a nyilvános területeken más felhasználókkal, ezeket az információkat minden felhasználó láthatja, és azok nyilvánosan terjeszthetőek kívülre.</li>
<li><strong>Az Ön hozzájárulásával</strong>: Személyes adatait bármilyen más célból, az Ön hozzájárulásával hozhatjuk nyilvánosságra.</li>
</ul>
<h1 className="data-title">Személyes adatainak megőrzése</h1>
<p>A Vállalat csak addig őrzi meg a Személyes Adatokat, amíg az ebben az Adatvédelmi Irányelvben meghatározott célok eléréséhez szükséges. Személyes adatait a jogi kötelezettségeink teljesítése érdekében (például, ha törvényi előírások szerint meg kell őriznünk adatait), viták rendezése és jogi megállapodásaink és szabályzataink érvényesítése szempontjából szükséges mértékig őrizzük meg és használjuk fel.</p>
<p>A Vállalat a Használati Adatokat is megőrzi belső elemzési célokra. A Használati Adatokat általában rövidebb időszakokra őrizzük meg, kivéve, ha ezeket az adatokat a biztonság megerősítésére vagy Szolgáltatásunk funkcionalitásának javítására használjuk, vagy ha jogilag kötelező őket hosszabb időszakokra megőrizni.</p>
<h1 className="data-title">Személyes adatainak átadása</h1>
<p>Információit, beleértve a Személyes Adatokat, a Vállalat működési irodáiban és az adatfeldolgozásban részt vevő más helyeken dolgozzák fel. Ez azt jelenti, hogy ezeket az információkat át lehet vinni – és fenntarthatók – olyan számítógépeken, amelyek az Ön államán, tartományán, országán vagy más kormányzati joghatóságán kívül helyezkednek el, ahol az adatvédelmi törvények eltérőek lehetnek az Ön joghatóságában érvényesektől.</p>
<p>Az ezen Adatvédelmi Irányelv elfogadásával, majd az ilyen információk benyújtásával Ön beleegyezik ebbe az átadásba.</p>
<p>A Vállalat minden ésszerűen szükséges lépést megtesz annak biztosítása érdekében, hogy adatait biztonságosan kezeljék és ezen Adatvédelmi Irányelvnek megfelelően, és Személyes Adatainak átadása nem történik meg olyan szervezetnek vagy országnak, hacsak nincsenek megfelelő biztonsági intézkedések az Ön adatainak és egyéb személyes információinak védelme érdekében.</p>
<h1 className="data-title">Személyes adatainak törlése</h1>
<p>Jogában áll törölni vagy kérni, hogy segítsünk a Személyes Adatainak törlésében, amelyeket Önről gyűjtöttünk.</p>
<p>Szolgáltatásunk lehetővé teszi, hogy bizonyos információkat töröljön magáról a Szolgáltatáson belül.</p>
<p>Bármikor frissítheti, módosíthatja vagy törölheti adatait a Fiókjába való bejelentkezéssel, ha van ilyen, és meglátogatva a fiókbeállítások részt, amely lehetővé teszi személyes adatai kezelését. Felveheti velünk a kapcsolatot is, hogy kérje az Ön általunk tárolt személyes információihoz való hozzáférését, azok helyesbítését vagy törlését.</p>
<p>Kérjük, vegye figyelembe azonban, hogy bizonyos információkat meg kell őriznünk, amikor jogi kötelezettségünk van vagy jogos alapunk van erre.</p>
<h1 className="data-title">Személyes adatainak nyilvánosságra hozatala</h1>
<h1 className="data-title">Üzleti tranzakciók</h1>
<p>Ha a Vállalat egyesülésben, felvásárlásban vagy eszközértékesítésben vesz részt, a Személyes Adatai átadásra kerülhetnek. Értesítést nyújtunk, mielőtt a Személyes Adatai átadásra kerülnének és más Adatvédelmi Irányelv alá kerülnének.</p>
<h1 className="data-title">Törvényi előírások</h1>
<p>Bizonyos körülmények között a Vállalatnak kötelező lehet a Személyes Adatait nyilvánosságra hoznia, ha törvény írja elő vagy ha nyilvános hatóságok (pl. bíróság vagy kormányzati ügynökség) érvényes kéréseire válaszolva.</p>
<h1 className="data-title">Egyéb jogi követelmények</h1>
<p>A Vállalat a jóhiszemű meggyőződéssel nyilvánosságra hozhatja a Személyes Adatait, ha ez szükséges:</p>
<ul>
<li>Jogszabályi kötelezettség teljesítéséhez</li>
<li>A Vállalat jogainak vagy tulajdonának védelméhez és védelméhez</li>
<li>A Szolgáltatással kapcsolatos lehetséges jogsértések megelőzéséhez vagy kivizsgálásához</li>
<li>A Szolgáltatás felhasználóinak vagy a nyilvánosság személyes biztonságának védelméhez</li>
<li>Jogi felelősség elleni védelemhez</li>
</ul>
<h1 className="data-title">Személyes adatainak biztonsága</h1>
<p>A Személyes Adatainak biztonsága fontos a számunkra, de ne feledje, hogy az interneten történő adattovábbítás vagy az elektronikus tárolás módszere nem 100%-ig biztonságos. Miközben kereskedelmi elfogadható módszereket igyekszünk alkalmazni a Személyes Adatai védelmére, nem tudjuk garantálni annak abszolút biztonságát.</p>
<h1 className="data-title">Gyermekek adatvédelme</h1>
<p>Szolgáltatásunk nem szól kifejezetten 13 évesnél fiatalabb személyeknek. Nem gyűjtünk tudatosan személyesen azonosítható információkat 13 évesnél fiatalabb személyektől. Ha Ön szülő vagy gyám és tudomása van arról, hogy Gyermeke Személyes Adatait adta meg nekünk, kérjük, lépjen kapcsolatba velünk. Ha tudomásunkra jut, hogy 13 évesnél fiatalabb személyektől gyűjtöttünk Személyes Adatokat szülői hozzájárulás nélkül, lépéseket teszünk az ilyen információk szervereinkről való törlésére.</p>
<p>Ha a Személyes Adatainak feldolgozásához szükséges jogi alapunk a hozzájárulás, és az Ön országában a szülői hozzájárulás szükséges, akkor a gyűjtés és használat előtt kérhetjük a szülő hozzájárulását.</p>
<h2>Linkek más weboldalakhoz</h2>
<p>Szolgáltatásunk tartalmazhat más weboldalakra mutató linkeket, amelyeket nem mi üzemeltetünk. Ha harmadik fél linkjére kattint, akkor azt a harmadik fél oldalára irányítjuk át. Erősen javasoljuk, hogy tekintse meg az Ön által látogatott minden oldal adatvédelmi irányelvét.</p>
<p>Nincs befolyásunk, és nem vállalunk felelősséget más harmadik fél weboldalainak tartalmáért, adatvédelmi irányelveiért vagy gyakorlataiért.</p>
<h2>Ezen adatvédelmi irányelv változásai</h2>
<p>Időről időre frissíthetjük adatvédelmi irányelveinket. Az új adatvédelmi irányelv érvénybe lépéséről e-mailben és/vagy Szolgáltatásunkon keresztül látható kiemelt értesítéssel tájékoztatjuk Önt, mielőtt a változás érvénybe lépne, és frissítjük az "Utolsó frissítés" dátumát ezen adatvédelmi irányelv tetején.</p>
<p>Tanácsoljuk, hogy időnként tekintse meg ezt az adatvédelmi irányelvet az esetleges változásokért. Ezen adatvédelmi irányelv változásai akkor válnak hatályossá, amikor azokat ezen az oldalon közzétesszük.</p>
<h1 className="data-title">Kapcsolatfelvétel</h1>
<p>Ha bármilyen kérdése van ezen adatvédelmi irányelvvel kapcsolatban, forduljon hozzánk:</p>
<ul>
<li>
<p>E-mailben: info@liafit.hu</p>
</li>
<li>
<p>Weboldalunkon ezen az oldalon: <a href="http://www.liafit.hu/datapolicy" rel="noreferrer" target="_blank">http://www.liafit.hu/datapolicy</a></p>
</li>
<li>
<p>Telefonszámon: +36703836041</p>
</li>
</ul>

<h1 className="data-title">
Hírlevél
</h1>
<p>
Az Adatkezelő az általa üzemeltetett honlapok hírleveleire való feliratkozóknak általában havonta, de naponta legfeljebb egy alkalommal újdonságokat, híreket és ajánlatokat tartalmazó online hírleveleket kézbesít.

A hírlevélre való feliratkozáshoz e-mail cím és egyes esetekben név megadása kötelező, ami elengedhetetlen az üzenetek kézbesítéséhez. Az adatokat mindaddig kezeljük, ameddig azok törlését az érintett nem kéri.

A leiratkozás lehetőségét minden hírlevélben egy közvetlen link biztosítja.
A megadott személyes adatok valódiságáért a felhasználó felel.
A hírlevéllel kapcsolatos adatkezelés a Nemzeti Adatvédelmi és Információszabadság Hatósághoz bejelentésre került.
</p>

<h1 className="data-title">
Adatkezelésben érintett külső szolgáltatók
</h1>

<h2><strong>
Google Ireland Ltd.
    </strong></h2>
    <p>Érintettség: webanalitika, marketing.</p>
    <a href="https://policies.google.com/privacy?hl=hu&gl=hu">Adatvédelmi tájékoztató</a>

    <h2><strong>
    booked4.us kft.
    </strong></h2>
    <p>Érintettség: foglaláshoz kapcsolódó adatok, e-mail cím, telfonszám, tejles név.</p>
    <a href="https://booked4.us/content/booked4.us/hu/privacy/">Adatvédelmi tájékoztató</a>

    
    <h2><strong>
    Intuit Inc.
    </strong></h2>
<p>Érintettség: hírlevélhez szükséges adatok, e-mail cím, teljes név.</p>
    <a href="https://www.intuit.com/privacy/statement/">Adatvédelmi tájékoztató</a>

    <h2><strong>
    
    </strong></h2>


<h1 className="data-title">
    Irányadó törvények
</h1>

<p>
A természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet) AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.)
</p>
<p>
Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről
</p>
<p>
A Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlása az előzetes tájékoztatás adatvédelmi követelményeiről
</p>
<p>
16/2011. sz. vélemény a viselkedésalapú online reklám bevált gyakorlatára vonatkozó EASA/IAB-ajánlásról
</p>
<p>
évi C. törvény az elektronikus hírközlésről (kifejezetten a 155.§-a)
</p>
<p>
évi XC. törvény az elektronikus információszabadságról
</p>
<p>
évi XLVIII. törvény – a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól (különösen a 6.§-a)
</p>
<p>
évi XLVII. törvény – a fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat tilalmáról;
</p>
<p>
évi CVIII. törvény – az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről (főképp a 13/A. §-a)
</p>
<p>
évi CXII. törvény – az információs önrendelkezési jogról és az információszabadságról (a továbbiakban: Infotv.)
</p>



{/* 
<h1 className="data-title">English:</h1>
    <h1>Privacy Policy</h1>
<p>Last updated: November 12, 2023</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank" rel="noreferrer">Privacy Policy Generator</a>.</p>
<h2>Interpretation and Definitions</h2>
<h3>Interpretation</h3>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h3>Definitions</h3>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
</li>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to László Júlia E.V., Mosonmagyaróvár, Szellőrózsa u. 33-37, 9200.</p>
</li>
<li>
<p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
</li>
<li>
<p><strong>Country</strong> refers to:  Hungary</p>
</li>
<li>
<p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><strong>Website</strong> refers to LIA FIT, accessible from <a href="http://www.liafit.hu" rel="noreferrer" target="_blank">http://www.liafit.hu</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<h2>Collecting and Using Your Personal Data</h2>
<h3>Types of Data Collected</h3>
<h4>Personal Data</h4>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Phone number</p>
</li>
<li>
<p>Usage Data</p>
</li>
</ul>
<h4>Usage Data</h4>
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
<h4>Tracking Technologies and Cookies</h4>
<p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
<ul>
<li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
<li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
</ul>
<p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" rel="noreferrer" target="_blank">Privacy Policies website</a> article.</p>
<p>We use both Session and Persistent Cookies for the purposes set out below:</p>
<ul>
<li>
<p><strong>Necessary / Essential Cookies</strong></p>
<p>Type: Session Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
</li>
<li>
<p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
</li>
<li>
<p><strong>Functionality Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
</li>
</ul>
<p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
<h3>Use of Your Personal Data</h3>
<p>The Company may use Personal Data for the following purposes:</p>
<ul>
<li>
<p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
</li>
<li>
<p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
</li>
<li>
<p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
</li>
<li>
<p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
</li>
<li>
<p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
</li>
<li>
<p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
</li>
<li>
<p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
</li>
<li>
<p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
</li>
</ul>
<p>We may share Your personal information in the following situations:</p>
<ul>
<li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
<li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
<li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
<li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
<li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
<li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
</ul>
<h3>Retention of Your Personal Data</h3>
<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
<h3>Transfer of Your Personal Data</h3>
<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
<h3>Delete Your Personal Data</h3>
<p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
<p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
<p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
<p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
<h3>Disclosure of Your Personal Data</h3>
<h4>Business Transactions</h4>
<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<h4>Law enforcement</h4>
<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
<h4>Other legal requirements</h4>
<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of the Company</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>
<h3>Security of Your Personal Data</h3>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<h2>Children's Privacy</h2>
<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
<h2>Links to Other Websites</h2>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<h2>Changes to this Privacy Policy</h2>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>
<p>By email: info@liafit.hu</p>
</li>
<li>
<p>By visiting this page on our website: <a href="http://www.liafit.hu/contactus" rel="noreferrer" target="_blank">http://www.liafit.hu/contactus</a></p>
</li>
<li>
<p>By phone number: +36703836041</p>
</li>
</ul> */}


    </div>
    </div>

    </>
        );
    }