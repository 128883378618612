import ReactGA from 'react-ga4';

ReactGA.send({ hitType: "pageview", page: "/impresszum", title: "Impresszum" });


export default function Impresszum () {
    return (
<>
<div className="data-policy">
    <div className="data-policy-container"> 
<h1 className="data-title">Magyar:</h1>

<h2 className="data-title">A honlapot üzemeltető és a tevékenységet végző vállalkozás neve:</h2>
<p>Vállakozás neve: László Júlia ev</p>
<p>Adóazonosító: 8464902735</p>
<p>Adószám: 68799054-1-28</p>
<p>Székhely: 9200 Mosonmagyaróvár Tündérfátyol utca 35.</p>


<h2 className="data-title">Elérhetőségek</h2>
<p>Telefon:+36703836041</p>
<p>E-mail: info@liafit.hu</p>
<p>Honlap: www.liafit.hu</p>


<h2 className="data-title">Tárhelyszolgáltató adatai:</h2>
<p>Cégnév: ININET Internet Kft. </p>
<p>Adószám: 23537646-2-42</p>
<p>EU Adószám: HU23537646</p>
<p>Bankszámlaszám: 10404065-50526648-88711003 (K&H bank)</p>
<p>Postacím: 1063 Budapest Szinyei Merse utca 10.</p>
<p>Cégjegyzékszám: 01-09-970252</p>
<p>E-mail cím: info@ininet.hu</p>
<p>Telefonszám: +36 20 293 9058 (hétfő-péntek 11 és 17 óra között)</p>
    </div>
    </div>

    </>
        );
    }