import "./home.css"
import { NavLink } from "react-router-dom";
import Footer from "../Footer.js";
import Instructors from "../Instructors.js"
import Classes from "../Classes.js";
import ReactGA from 'react-ga4';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {ReactComponent as Siluett} from '../../../siluett.svg'

 


ReactGA.send({ hitType: "pageview", page: "/", title: "Kezdolap" });

export default function Home () {
    
    const handleClick = () => {
        // Track the click event.
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'Booking',
          eventAction: 'Foglalas Gomb',
        });
      };

return (
<div className="home-main" id="home">
    <div className="home-main-layer">
    
    {/* <div className="new-main-title">
    
      <div  className="new-main-hero drop-in">
      </div>
      <div className="new-main-hero-2 drop-in">
        <div className="new-main-hero-2-tile">
      <h1>Lia Fit</h1>
        <p>Üdvözöllek életmód és sportrehabilitációs központunkban, ahol békés és fiatalító környezetet kínálunk jóga, sportrehabilitáció és egyéb, holisztikus gyakorlásodhoz. <br></br> Mindegy, hogy javítani szeretnéd a funkcionális hajlékonyságodat, növelni az erődet, csökkenteni szeretnéd a stresszt, vagy egyszerűen csak egy pillanatnyi nyugalomra vágysz, 
        esetleg sérülésből szeretnél felépülni vagy az eddigi egészségtelen életmódból fakadó problémát szeretnél megoldani, 
        nálunk megtalálod az igényeidnek megfelelő órát vagy szolgáltatást. 
        <br></br>Namaste.
        </p>
        <NavLink onClick={handleClick}   to="/book"><button className="drop-in-2">Foglalás <CalendarMonthIcon /> </button></NavLink></div>
      </div>
    </div> */}


        <div className="home-main-title ">

            <div  className="home-main-hero drop-in">
                <h1 style={{color:"white"}}>Lia Fit</h1>       
                <NavLink onClick={handleClick}   to="/book"><button className="drop-in-2">Foglalás <CalendarMonthIcon /> </button></NavLink>

            </div>
        </div> 



        <div id="instructorsid" className="home-main-container">
         <div className="headline" >
        <p>Üdvözöllek életmód és sportrehabilitációs központunkban, ahol békés és fiatalító környezetet kínálunk jóga, sportrehabilitáció és egyéb, holisztikus gyakorlásodhoz. <br></br> Mindegy, hogy javítani szeretnéd a funkcionális hajlékonyságodat, növelni az erődet, csökkenteni szeretnéd a stresszt, vagy egyszerűen csak egy pillanatnyi nyugalomra vágysz, 
        esetleg sérülésből szeretnél felépülni vagy az eddigi egészségtelen életmódból fakadó problémát szeretnél megoldani, 
        nálunk megtalálod az igényeidnek megfelelő órát vagy szolgáltatást. 
        <br></br>Namaste.
        </p></div>

        <Siluett className="separator-sliuett" />

        <section className="no-background-section">
          <div className="custom-titles slide">
          Ismerd meg<br></br>  <span className="infocus">szakértő </span><br></br>oktatóinkat,<br></br>
          </div>
          <h3 className="undertitle">

          akik elkötelezettek abban, hogy segítsenek elérni a céljaidat. Minden tanár egyedi stílusát és tapasztalatát hozza stúdiónkba, így változatos és dinamikus órákat hoznak létre. Akár kezdő, akár tapasztalt sportoló vagy, edzőink személyre szabott figyelemmel és támogatással végigvezetnek minden pózon. Ismerd meg csapatunkat alább, és találd meg az igényeidnek megfelelő oktatót.

          </h3>
          
          <Instructors />
          </section>


            </div>
        <div id="classesid" className="home-main-container">
  <div className="custom-titles slide">
  Fedezd fel<br></br> 
  a <span className="infocus"> számodra </span><br></br>  
  <span className="infocus"> tökéletes </span> órát<br></br>

          </div>

  <h3 className="undertitle">
  Stúdiónk számos lehetőséget kínál szakértő oktatók által, akik személyre szabott figyelemmel és támogatással végigvezetnek minden gyakorlaton. Akár hajlékonyságod, erőnléted vagy általános jóléted szeretnéd javítani, óráinkat úgy alakítottuk ki, hogy segítsenek elérni céljaid. A kezdőbarát óráktól a haladó foglalkozásokig mindenki megtalálhatja a számára legjobban hozzáillőt. Fedezd fel alább az óráinkat, és találd meg az igényeidnek leginkább megfelelőt. 
              </h3>
<h3 className="undertitle">
Életmód és Sportrehabilitációs Központunkban hiszünk abban, hogy a sport nem csak fizikai erőnlét, a jóga pedig nem csak nyújtógyakorlatokból áll. Ez egy holisztikus utazás, amely magában foglalja a testi, mentális, érzelmi és spirituális jólétet. Ezért hoztunk létre egy blogrészt, ahol megosztunk meglátásokat, tippeket és inspirációkat is, amelyek segítenek az önismeret és fejlődés felé vezető úton. A tudatosságtól és a meditációtól az egészséges táplálkozásig és az öngondoskodásig, blogunk egy olyan hely, ahol az egészséges életmód minden aspektusát igyekszünk közérthető formában elérhetővé tenni. 

Te csak pihenj meg a mozgalmas napod során, és fedezd fel blogrészünket. Reméljük, találsz valami olyat, amivel tudsz azonosulni, és inspirál, hogy folytasd az önismeret és fejlődés felé vezető utad.
</h3>


<Classes />
        </div> 
    </div>
  
     

    <Footer />
</div>
);
}